import { PublicClientApplication, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import auth from "config/auth.json";

export function onLoginSuccess(event: EventMessage, msalInstance: PublicClientApplication) {
  const payload = event.payload as AuthenticationResult;
  const account = payload.account;
  const regex = new RegExp(auth.adb2cSignInPolicy, "i");
  if (payload.authority && payload.authority.match(regex)) {
    msalInstance.setActiveAccount(account);
  }
}
