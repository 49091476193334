import axios, { AxiosRequestTransformer } from "axios";
import { ixicoDateTransformer } from "..";

/**
 * It returns the list of the Axios default request transformers
 * @returns
 */
export const defaultTransformers = (): AxiosRequestTransformer[] => {
  const { transformRequest } = axios.defaults;
  if (!transformRequest) {
    return [];
  }
  else if (transformRequest instanceof Array) {
    return transformRequest;
  }
  else {
    return [transformRequest];
  }
};

/**
 * It returns a list of axios transformers to be used when performing
 * an API call to the Project Oasis services. This list includes the
 * "ixico" made transformers
 */
export function ixicoTransformers(): AxiosRequestTransformer[] {
  return [ixicoDateTransformer, ...defaultTransformers()];
}
