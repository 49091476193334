import { useQuery } from "react-query";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { getQueryState } from "lib/common";
import { IPublicClientApplication } from "@azure/msal-browser";
import { IReferenceData, IReferenceDataQuery } from "./interfaces";

/**
 * Retrieve reference data for the given refTypeOid.
 *
 * @param queryFn The reference data query function.
 * @param refTypeOid The reference data type.
 * @returns query state, data and error properties.
 */
export function useReferenceDataQuery<TData, TRefTypeOid>(
  queryFn: (instance: IPublicClientApplication, refTypeOid: TRefTypeOid) => Promise<IReferenceData<TData>>,
  refTypeOid: TRefTypeOid
): IReferenceDataQuery<TData> {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const query = useQuery(
    ["getReferenceData", refTypeOid],
    async () => await queryFn(instance, refTypeOid),
    {
      enabled: isAuthenticated
    }
  );

  const { data, error } = query;

  return {
    state: getQueryState(query.status),
    data,
    error
  };
}
