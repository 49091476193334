import { createIcon } from "@chakra-ui/react";

export const Filter = createIcon({
  displayName: "Filter",
  viewBox: "0 0 16 10",
  path: (
    <path d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z" fill="currentColor"/>
  ),
});

