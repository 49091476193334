import { PublicClientApplication, EventMessage } from "@azure/msal-browser";
import { SESSION_EXPIRED_ROUTE_PATH } from "pages/session-expired/constants";
import { isAcquireTokenFailure, isForgotPassword, isInvalidGrant, isLoginSuccess } from "./state";
import { getIdTokenClaims } from "./getIdTokenClaims";
import { onAcquireTokenFailure } from "./onAcquireTokenFailure";
import { addBackForwardCacheLoadListener } from "./onBackForwardCacheLoad";
import { onLoginSuccess } from "./onLoginSuccess";

/**
 * Registers a callback function to handle events raised by MSAL.
 * @param msalInstance
 */
export function addAuthEventCallback(msalInstance: PublicClientApplication) {
  /**
   * The callback function used to handle events raised by MSAL.
   * @param event
   */
  function authEventCallback(event: EventMessage) {
    if (!!event.eventType.match(/failure/i)) {
      console.warn(event.timestamp, event.eventType, event.interactionType, event.error);
    }

    const invalidGrant = isInvalidGrant(event);
    const loginSuccess = isLoginSuccess(event);
    const idTokenClaims = getIdTokenClaims(event, loginSuccess);
    const forgotPassword = isForgotPassword(idTokenClaims);
    const acquireTokenFailure = isAcquireTokenFailure(event);

    if (invalidGrant) {
      // if the refresh token has been revoked, or the user has been inactive for too long between
      // refresh token user journey requests, logout and redirect to the session expired page
      msalInstance.logoutRedirect({
        postLogoutRedirectUri: SESSION_EXPIRED_ROUTE_PATH,
      });
    }
    else if (forgotPassword) {
      // force the user to sign-in with their new password after a password reset
      msalInstance.logoutRedirect();
    }
    else if (loginSuccess) {
      onLoginSuccess(event, msalInstance);
    }
    else if (acquireTokenFailure) {
      onAcquireTokenFailure(event, msalInstance);
    }
  }

  addBackForwardCacheLoadListener();
  const callbackId = msalInstance.addEventCallback(authEventCallback);

  return {
    callbackId,
    authEventCallback
  };
}
