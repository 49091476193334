import { createIcon } from "@chakra-ui/react";

export const Clock = createIcon({
  displayName: "ClockIcon",
  path: (
    <svg width="99" height="30" viewBox="0 0 99 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="12" y1="4.5" x2="12" y2="12.8" stroke="currentColor" />
      <line x1="11.7157" y1="12.5" x2="18.0307" y2="17" stroke="currentColor" />
      <circle cx="12" cy="12" r="11.5" stroke="currentColor" />
    </svg>
  ),
});
