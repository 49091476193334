
import { createIcon } from "@chakra-ui/react";

const VIEWBOX_X = -4;
const OFFSET = 5;
const X = -OFFSET;

export const FirstPageIcon = createIcon({
  displayName: "FirstPageIcon",
  viewBox: `${VIEWBOX_X} 0 12 20`,
  path: (
    <>
      <line x1={X} y1="0" x2={X} y2="20" stroke="currentColor" strokeWidth="2"></line>
      <path d="M 10.0401 0 L 11.5 1.4599 L 2.8869 10 L 11.5 18.5402 L 10.0402 20 L -0.0328 10 L 10.0401 0 Z" fill="currentColor"></path>
    </>
  ),
});
