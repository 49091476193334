import { toDate, format, utcToZonedTime } from "date-fns-tz";
import { addMinutes } from "date-fns";

/**
 * Ixico "Axios" request transformer to convert a "Date" object
 * into an "Ixico time stamp" before sending the HTTP request
 *
 * @param data
 * @returns
 */
export function ixicoDateTransformer<T>(data: T): string | T | T[] | Record<string, string> {
  if (data instanceof Date) {
    return fromDateToIxicoString(data);
  }
  if (Array.isArray(data)) {
    return data.map(item => ixicoDateTransformer(item));
  }
  if (typeof data === "object" && data !== null) {
    return Object.fromEntries(Object.entries(data).map(([ key, val ]) => [ key, ixicoDateTransformer(val) ]));
  }
  return data;
}

const formatInTimeZone = (date: Date, fmt: string, tz: string) =>
  format(utcToZonedTime(date, tz),
    fmt,
    { timeZone: tz });

/**
 * Serialises a Date with the "Ixico format" (UTC).
 *
 * @param date
 * @returns The string representation
 */
export function fromDateToIxicoString(date: Date): string {
  const dateString = formatInTimeZone(date, "yyyy-MM-dd?HH:mm:ssX", "UTC");
  return dateString.split("?").join("T");
}

/**
 * Serialises a Date with the "Ixico format" (UTC),
 * without making adjustments for the timezone offset.
 *
 * @param date
 * @returns The string representation
 */
export function fromLocalDateToIxicoString(date: Date): string {
  // The Date returned by the single date picker has the time set as 00:00,
  // but the Date can include a timezone offset based on the user's location,
  // e.g. dates that fall within British Summer Time are +01:00 in the UK.
  // This can result in the wrong date being selected when the Date is converted
  // to UTC, as e.g. "2023-08-25 00:00+01:00" can become "2023-08-24 23:00Z" (the previous day).
  // To avoid this, we subtract the date's timezone offset in minutes before the UTC
  // conversion.
  const correctedDate = addMinutes(date, -date.getTimezoneOffset());
  return fromDateToIxicoString(correctedDate);
}

/**
 * It returns the ixico time stamp for the start of the day in UTC.
 * @returns YYYY-MM-DDT00:00:00Z
 */
export function getUtcStartOfDayInIxicoString() {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return fromLocalDateToIxicoString(date);
}

/**
 * Serialises a Date for use as part of a file system path, in UTC.
 *
 * @param date
 * @returns The string representation
 */
export function fromDateToIxicoPathString(date: Date): string {
  return formatInTimeZone(date, "yyyy-MM-dd-HHmm-ss-SSS", "UTC");
}

/**
 * It converts an ixico time stamp: yyyy-MM-ddTHH:mm:ss:Z into a Date.
 * The Date will have the time set according to the browser time zone:
 * Example (Browser with Italian time zone and summer time):
 * - Input -> 2022-04-04T23:27:04Z
 * - Output -> 2022-04-05T01:27:04 GMT+0200 (Central European Summer Time)
 *
 * @param timeStamp
 * @returns Date obj or undefined
 */
export function ixicoTimeStampToUserTimeZoneDate(timeStamp?: string): Date | undefined {
  if (timeStamp) {
    return toDate(timeStamp);
  }
}

/**
 * It returns the current Date object
 * according to your time zone.
 *
 * @returns the date object
 */
export function getCurrentDate(): Date {
  return new Date();
}

/**
 * Returns the ISO formatted Date string for the given `date` without any timezone offset.
 */
export function fromDateToISOString(date: Date): string {
  return format(date, "yyyy-MM-dd?HH:mm:ss.SSS").replace("?", "T") + "Z";
}
