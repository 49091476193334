import { createIcon } from "@chakra-ui/react";

export const ErrorIcon = createIcon({
  displayName: "ErrorIcon",
  path:
    (
      <svg width="25" height="21" viewBox="1 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.5194 16.3601L15.346 1.51808C14.8359 0.692788 13.9222 0.200012 12.902 0.200012C11.8817 0.200012 10.968 0.692788 10.4579 1.51808L1.28458 16.3601C0.774443 17.1855 0.774443 18.1709 1.28458 18.9963C1.79472 19.8216 2.70831 20.3143 3.72859 20.3143H22.0753C23.0956 20.3143 24.0092 19.8216 24.5194 18.9963C25.0295 18.1709 25.0295 17.1855 24.5194 16.3601ZM23.3017 18.3396C23.0457 18.7537 22.5872 19.0009 22.0753 19.0009H3.72859C3.21662 19.0009 2.75819 18.7537 2.50225 18.3396C2.24631 17.9255 2.24631 17.431 2.50225 17.0169L11.6757 2.17484C11.9316 1.7607 12.3901 1.51348 12.902 1.51348C13.4139 1.51348 13.8724 1.7607 14.1283 2.17484L23.3017 17.0169C23.5576 17.431 23.5576 17.9255 23.3017 18.3396Z" fill="currentColor" />
        <path d="M13.6047 6.75467H12.1986V13.3219H13.6047V6.75467Z" fill="currentColor" />
        <path d="M12.9017 14.6354C12.3848 14.6354 11.9643 15.0282 11.9643 15.5111C11.9643 15.9939 12.3848 16.3867 12.9017 16.3867C13.4186 16.3867 13.8391 15.9939 13.8391 15.5111C13.8391 15.0282 13.4186 14.6354 12.9017 14.6354Z" fill="currentColor" />
      </svg>
    ),
});
