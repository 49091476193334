import { createIcon } from "@chakra-ui/react";

export const FileFolderPickerIcon = createIcon({
  displayName: "FileFolderPickerIcon",
  viewBox: "0 0 30 30",
  path: (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.9638 6.03621C22.2956 5.36805 21.4072 5.00007 20.4622 5.00007L8.53775 5C6.58698 5.00011 4.99996 6.58717 5.00007 8.53779L5 20.4623C5.00007 22.413 6.58713 24 8.53779 24H20.4622C22.4129 24 24 22.413 24 20.4623V8.53786C24 7.59283 23.6321 6.7044 22.9638 6.03621ZM22.8867 20.4623C22.8867 21.7991 21.7991 22.8867 20.4622 22.8867H8.53779C7.20096 22.8867 6.11332 21.7991 6.11328 20.4623L6.11336 8.53775C6.11328 7.20096 7.20092 6.11336 8.53779 6.11328L20.4622 6.11336C21.1098 6.11336 21.7187 6.36551 22.1766 6.82341C22.6346 7.28134 22.8868 7.89015 22.8867 8.53779V20.4623Z" fill="#2E9FAE"/>
      <path d="M17.8446 11.6915L14.8934 8.74035C14.7891 8.63596 14.6475 8.57733 14.4999 8.57733C14.3522 8.57733 14.2106 8.636 14.1062 8.74039L11.155 11.6917C10.9377 11.9091 10.9377 12.2615 11.155 12.4789C11.2637 12.5876 11.4062 12.642 11.5486 12.642C11.6911 12.642 11.8336 12.5876 11.9422 12.4789L13.9431 10.478L13.9432 16.4158C13.9432 16.7233 14.1924 16.9725 14.4998 16.9725C14.8072 16.9725 15.0565 16.7232 15.0565 16.4158L15.0564 10.4777L17.0574 12.4787C17.2748 12.6961 17.6273 12.6961 17.8446 12.4787C18.062 12.2613 18.062 11.9089 17.8446 11.6915Z" fill="#2E9FAE"/>
      <path d="M19.8662 19.3094H9.13416C8.82674 19.3094 8.57751 19.5586 8.57751 19.866C8.57751 20.1734 8.82674 20.4227 9.13416 20.4227H19.8662C20.1736 20.4227 20.4228 20.1734 20.4228 19.866C20.4228 19.5586 20.1736 19.3094 19.8662 19.3094Z"/>
    </svg>
  )
});
