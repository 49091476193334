import { createIcon } from "@chakra-ui/react";

export const StudyContactsIcon = createIcon({
  displayName: "StudyContactsIcon",
  path: (
    <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0418 6.125H19.0002V7.875H15.0418V6.125ZM13.4585 10.5H19.0002V12.25H13.4585V10.5ZM15.8335 14.875H19.0002V16.625H15.8335V14.875ZM1.5835 19.25C1.5835 17.3935 2.25076 15.613 3.43849 14.3003C4.62622 12.9875 6.23712 12.25 7.91683 12.25C9.59653 12.25 11.2074 12.9875 12.3952 14.3003C13.5829 15.613 14.2502 17.3935 14.2502 19.25H12.6668C12.6668 17.8576 12.1664 16.5223 11.2756 15.5377C10.3848 14.5531 9.17661 14 7.91683 14C6.65705 14 5.44887 14.5531 4.55807 15.5377C3.66727 16.5223 3.16683 17.8576 3.16683 19.25H1.5835ZM7.91683 11.375C5.29245 11.375 3.16683 9.02563 3.16683 6.125C3.16683 3.22438 5.29245 0.875 7.91683 0.875C10.5412 0.875 12.6668 3.22438 12.6668 6.125C12.6668 9.02563 10.5412 11.375 7.91683 11.375ZM7.91683 9.625C9.66641 9.625 11.0835 8.05875 11.0835 6.125C11.0835 4.19125 9.66641 2.625 7.91683 2.625C6.16725 2.625 4.75016 4.19125 4.75016 6.125C4.75016 8.05875 6.16725 9.625 7.91683 9.625Z" fill="currentColor"/>
    </svg>
  ),
});
