import { createIcon } from "@chakra-ui/react";

export const InformationToast = createIcon({
  displayName: "InformationToast",
  viewBox: "0 0 30 30",
  path: (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.6812 19.4668L17.0367 7.09839C16.6116 6.41065 15.8502 6 15 6C14.1498 6 13.3884 6.41065 12.9633 7.09839L5.31884 19.4667C4.89372 20.1545 4.89372 20.9758 5.31884 21.6635C5.74395 22.3513 6.50528 22.7619 7.35551 22.7619H22.6445C23.4946 22.7619 24.256 22.3513 24.6812 21.6636C25.1063 20.9758 25.1063 20.1545 24.6812 19.4668ZM23.6664 21.1163C23.4531 21.4614 23.0711 21.6674 22.6445 21.6674H7.35551C6.92887 21.6674 6.54684 21.4614 6.33356 21.1163C6.12028 20.7712 6.12028 20.3592 6.33356 20.0141L13.9781 7.64569C14.1914 7.30058 14.5734 7.09456 15 7.09456C15.4266 7.09456 15.8087 7.30058 16.0219 7.64569L23.6664 20.0141C23.8797 20.3592 23.8797 20.7712 23.6664 21.1163Z" fill="black" />
      <path d="M14.1955 20.0267H15.3672V14.554H14.1955V20.0267Z" fill="currentColor" />
      <g filter="url(#filter0_d_991_851)">
        <path d="M14.7811 13.4594C15.2118 13.4594 15.5623 13.1321 15.5623 12.7297C15.5623 12.3273 15.2118 12 14.7811 12C14.3504 12 13.9999 12.3273 13.9999 12.7297C13.9999 13.1321 14.3503 13.4594 14.7811 13.4594Z" fill="currentColor" />
        <path d="M15.0623 12.7297C15.0623 12.8244 14.9683 12.9594 14.7811 12.9594C14.5938 12.9594 14.4999 12.8244 14.4999 12.7297C14.4999 12.6351 14.5938 12.5 14.7811 12.5C14.9683 12.5 15.0623 12.635 15.0623 12.7297Z" stroke="currentColor" />
      </g>
      <defs>
        <filter id="filter0_d_991_851" x="10" y="12" width="9.56226" height="9.45941" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_991_851" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_991_851" result="shape" />
        </filter>
      </defs>
    </svg>
  )
});
