import { createIcon } from "@chakra-ui/react";

export const DoneIcon = createIcon({
  displayName: "DoneIcon",
  viewBox: "0 0 18 16",
  path: (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line y1="-0.75" x2="8.96908" y2="-0.75" transform="matrix(0.668965 0.743294 -0.668965 0.743294 0 8.66666)" stroke="currentColor" strokeWidth="1.5" />
      <line y1="-0.75" x2="17.9382" y2="-0.75" transform="matrix(0.668965 -0.743294 0.668965 0.743294 6 15.3333)" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  ),
});
