import { useMsal } from "@azure/msal-react";
import { useQuery, UseQueryResult } from "react-query";
import { IUserProps } from ".";
import { TRIAL_TRACKER_APP_ID } from "../../constants/app";
import { getUserPermissions, IUserPermission } from "../../services/security";

export interface IUseUserPermissionQueryResult {
  query: UseQueryResult<IUserPermission[] | undefined, unknown>;
  permissions: IUserPermission[] | undefined;
}

export function useUserPermissionQuery({ userId }: IUserProps): IUseUserPermissionQueryResult {
  const { instance } = useMsal();

  const query = useQuery(
    ["getUserPermissions", TRIAL_TRACKER_APP_ID, userId],
    async () => await getUserPermissions(instance, { appId: TRIAL_TRACKER_APP_ID, userId }),
    { enabled: !!userId }
  );

  const permissions = query.data;

  return {
    query,
    permissions
  };
}
