import { createIcon } from "@chakra-ui/react";

export const ChevronLeft = createIcon({
  displayName: "ChevronLeft",
  viewBox: "0 0 12 20",
  path: (
    <path d="M 10.0401 0 L 11.5 1.4599 L 2.8869 10 L 11.5 18.5402 L 10.0402 20 L -0.0328 10 L 10.0401 0 Z" fill="currentColor"/>
  ),
});

