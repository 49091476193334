import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

import settings from "config/settings.json";
import { Metric as WebVitalsMetric } from "web-vitals";

const { appInsightsConnectionString } = settings;

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: appInsightsConnectionString,
    enableDebug: false,
    disableExceptionTracking: false,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
  }
});
appInsights.loadAppInsights();

/**
 * Track a web vitals metric as an Application Insights custom event.
 * @param webVitalsMetric The web vitals metric to track.
 */
function trackWebVitalsEvent(webVitalsMetric: WebVitalsMetric) {
  const { name, ...props } = webVitalsMetric;
  appInsights.trackEvent({ name }, props);
}

export { reactPlugin, appInsights, trackWebVitalsEvent };

