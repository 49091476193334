import { createIcon } from "@chakra-ui/react";

export const SuccessToast = createIcon({
  displayName: "SuccessToast",
  viewBox: "0 0 30 30",
  path: (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.6812 19.4668L17.0367 7.09839C16.6116 6.41065 15.8502 6 15 6C14.1498 6 13.3884 6.41065 12.9633 7.09839L5.31884 19.4667C4.89372 20.1545 4.89372 20.9758 5.31884 21.6635C5.74395 22.3513 6.50528 22.7619 7.35551 22.7619H22.6445C23.4946 22.7619 24.256 22.3513 24.6812 21.6636C25.1063 20.9758 25.1063 20.1545 24.6812 19.4668ZM23.6664 21.1163C23.4531 21.4614 23.0711 21.6674 22.6445 21.6674H7.35551C6.92887 21.6674 6.54684 21.4614 6.33356 21.1163C6.12028 20.7712 6.12028 20.3592 6.33356 20.0141L13.9781 7.64569C14.1914 7.30058 14.5734 7.09456 15 7.09456C15.4266 7.09456 15.8087 7.30058 16.0219 7.64569L23.6664 20.0141C23.8797 20.3592 23.8797 20.7712 23.6664 21.1163Z" fill="currentColor" />
      <line y1="-0.75" x2="4.4" y2="-0.75" transform="matrix(0.668965 0.743294 -0.668965 0.743294 10 16.613)" stroke="black" strokeWidth="1.5" />
      <line y1="-0.75" x2="8" y2="-0.75" transform="matrix(0.668965 -0.743294 0.668965 0.743294 13 19.9464)" stroke="black" strokeWidth="1.5" />
    </svg>
  )
});
