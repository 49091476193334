/*
 * Modified version of Microsoft's MSAL Browser FetchClient,
 * to support handling "invalid_grant" error.
 */

import { Constants, INetworkModule, NetworkRequestOptions, NetworkResponse } from "@azure/msal-common";
import { BrowserAuthError } from "@azure/msal-browser";
import { IResponseBodyError } from "./interfaces";

/**
 * This class implements the Fetch API for GET and POST requests.
 * See more here: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 */
export class FetchClient implements INetworkModule {
  /**
   * Fetch Client for REST endpoints - Get request
   * @param url
   * @param headers
   * @param body
   */
  async sendGetRequestAsync<T>(url: string, options?: NetworkRequestOptions): Promise<NetworkResponse<T>> {
    let response: Response;
    try {
      response = await fetch(url, {
        method: "GET",
        headers: this.getFetchHeaders(options)
      });
    }
    catch (e) {
      if (window.navigator.onLine) {
        throw BrowserAuthError.createGetRequestFailedError(e as string, url);
      }
      else {
        throw BrowserAuthError.createNoNetworkConnectivityError();
      }
    }

    try {
      const headers = this.getHeaderDict(response.headers);
      const body = await response.json() as T;
      const result: NetworkResponse<T> = {
        headers,
        body,
        status: response.status
      };

      return result;
    }
    catch (e) {
      throw BrowserAuthError.createFailedToParseNetworkResponseError(url);
    }
  }

  /**
   * Fetch Client for REST endpoints - Post request
   * @param url
   * @param headers
   * @param body
   */
  async sendPostRequestAsync<T>(url: string, options?: NetworkRequestOptions): Promise<NetworkResponse<T>> {
    const reqBody = (options && options.body) || Constants.EMPTY_STRING;

    let response: Response;
    try {
      response = await fetch(url, {
        method: "POST",
        headers: this.getFetchHeaders(options),
        body: reqBody
      });
    }
    catch (e) {
      if (window.navigator.onLine) {
        throw BrowserAuthError.createPostRequestFailedError(e as string, url);
      }
      else {
        throw BrowserAuthError.createNoNetworkConnectivityError();
      }
    }

    let parsedResponse: NetworkResponse<T>;

    try {
      parsedResponse = {
        headers: this.getHeaderDict(response.headers),
        body: await response.json() as T,
        status: response.status
      };
    }
    catch (e) {
      throw BrowserAuthError.createFailedToParseNetworkResponseError(url);
    }

    const parsedResponseError = parsedResponse.body as IResponseBodyError;
    if (parsedResponseError?.error === "invalid_grant") {
      throw new BrowserAuthError("invalid_grant", parsedResponseError?.error_description);
    }

    return parsedResponse;
  }

  /**
   * Build Request Headers for use with the Fetch API.
   * @param inputHeaders
   */
  private getFetchHeaders(options?: NetworkRequestOptions): Headers {
    const headers = new Headers();
    if (!(options && options.headers)) {
      return headers;
    }

    const optionsHeaders = options.headers;
    Object.keys(optionsHeaders).forEach((key) => {
      headers.append(key, optionsHeaders[key]);
    });

    return headers;
  }

  /**
   * Convert the Response's enumerable Headers to a dictionary.
   * @param headers
   */
  private getHeaderDict(headers: Headers): Record<string, string> {
    const headerDict: Record<string, string> = {};
    headers.forEach((value: string, key: string) => {
      headerDict[key] = value;
    });

    return headerDict;
  }
}
