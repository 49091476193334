import { useMemo } from "react";
import { QueryKey } from "react-query";
import { IContext } from ".";

export function useContextQueryKey(queryKey: QueryKey, context: IContext, appId?: string): QueryKey {
  const _queryKey = useMemo(() => Array.from(
    Array.isArray(queryKey) ?
      new Set(
        [
          ...queryKey,
          appId,
          context?.contextType,
          context?.contextUuid
        ]
      ) :
      new Set(
        [
          queryKey,
          appId,
          context?.contextType,
          context?.contextUuid
        ]
      )
  ), [queryKey, appId, context?.contextType, context?.contextUuid]);

  return _queryKey;
}
