import { QueryState } from ".";

/**
 * Returns an aggregate `QueryState` from a `QueryState[]`.
 * @param queryStates The `QueryState[]`.
 * @returns `QueryState.loading` if ANY states are `QueryState.loading`,
 * `QueryState.noItems` if ALL states are `QueryState.noItems`,
 * `QueryState.success` if ALL states are `QueryState.success` or `QueryState.noItems`
 *  (at least one state must be `QueryState.success`),
 * `QueryState.error` if ANY states are `QueryState.error`,
 * `QueryState.idle` if the previous conditions are not met.
 */
export const getQueryStateAll = (queryStates: QueryState[]): QueryState => {
  if (queryStates.some(state => state === QueryState.error)) {
    return QueryState.error;
  }

  if (queryStates.some(state => state === QueryState.loading)) {
    return QueryState.loading;
  }

  if (queryStates.every(state => state === QueryState.noItems)) {
    return QueryState.noItems;
  }

  if (queryStates.every(state => state === QueryState.success || state === QueryState.noItems)) {
    return QueryState.success;
  }

  return QueryState.idle;
};
