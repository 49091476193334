import { Button } from "@chakra-ui/button";
import { DefaultLayout } from "layouts/default";
import { usePageTitle } from "lib/common/use-page-title";
import { ButtonStyles } from "components/common/button";
import { Flex, Stack, Text } from "@chakra-ui/react";
import { IErrorLayoutProps } from "./interfaces";
import { useMemo } from "react";
import { HALF_SPACING } from "constants/spacing";

const { sizes, variants } = ButtonStyles;

/**
 * Generic error layout that displays a heading, message(s), and a button.
 */
export function ErrorLayout(props: IErrorLayoutProps) {
  const {
    title,
    heading,
    message,
    messageTestId,
    buttonText,
    buttonTestId,
    onButtonClick
  } = props;

  usePageTitle(title);

  // support either a single message or an array of messages, with each message as a paragraph
  const messages = useMemo(() => typeof message === "string" ? [message] : message, [message]);

  return (
    <DefaultLayout heading={heading}>
      <Stack spacing={HALF_SPACING}>
        {messages.map((message, index) => (
          <Text
            key={`message-${index}`}
            data-testid={messageTestId}
            textStyle="body"
          >
            {message}
          </Text>
        ))}
      </Stack>
      <Flex alignItems="end" flexDirection="column">
        <Button
          data-testid={buttonTestId}
          onClick={onButtonClick}
          size={sizes.medium}
          variant={variants.primary}
        >
          {buttonText}
        </Button>
      </Flex>
    </DefaultLayout>
  );
}
