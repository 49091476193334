import { Grid, GridItem, Box, Link, Text } from "@chakra-ui/layout";
import { ChakraProps, Image } from "@chakra-ui/react";
import { IChildren } from "lib/common/interfaces";
import React from "react";
import { LogoFull, Loader } from "styles/icons";
import { ISignInLayoutProps, ISignInLinkProps } from "./interfaces";

function CardContainer({ children }: IChildren) {
  return (
    <Box
      width="420px"
      height="480px"
      padding="42px"
      position="absolute"
      right="-100px"
      top="60px"
      background="white"
      boxShadow="0px 4px 4px rgba(203, 203, 203, 0.25)"
      borderRadius="10px"
      textAlign="left"
      opacity={"0.85"}
    >
      {children}
    </Box>
  );
}

function LogoContainer({ children }: IChildren) {
  return (
    <Box marginBottom="30px">
      {children}
    </Box>
  );
}

function Title({ children, ...rest }: IChildren & ChakraProps) {
  return (
    <Box
      as="h1"
      marginBottom="10px"
      fontSize="24px"
      fontWeight="bold"
      {...rest}
    >
      {children}
    </Box>
  );
}

export function SubTitleContent({ children }: IChildren) {
  return (
    <Text textStyle="cardBody">{children}</Text>
  );
}

function SubTitle({ children, ...rest }: IChildren & ChakraProps) {
  return (
    <Box marginBottom="10px" {...rest}>
      {children}
    </Box>
  );
}

export function SignInContent({ children, title, subTitle, titleTestId, subTitleTestId }: IChildren & ISignInLayoutProps) {
  return (
    <>
      <Title data-testid={titleTestId}>{title}</Title>
      <SubTitle data-testid={subTitleTestId}>{subTitle}</SubTitle>
      {children}
    </>
  );
}

export function SignInLink({ children, href, ...rest }: IChildren & ISignInLinkProps) {
  return (
    <Link
      href={href}
      marginTop="13px"
      color="#2da0ae"
      textDecor="underline"
      fontWeight="600"
      {...rest}
    >
      {children}
    </Link>
  );
}

function LoadingContainer({ children }: IChildren) {
  return (
    <Box
      textAlign="center"
      marginTop="40px"
      data-testid="loading-container"
    >
      {children}
    </Box>
  );
}

export const LoadingProgress = React.memo(() => {
  return (
    <LoadingContainer>
      <Loader width="100px" height="100px" />
    </LoadingContainer>
  );
}, () => true);

export function SignInLayout({ children }: IChildren) {
  return (
    <Grid
      data-testid="sign-in-layout"
      width="100vw"
      height="100vh"
      minWidth="1200px"
      minHeight="700px"
      justifyContent="center"
      alignContent="center"
      overflowY="auto"
      bg="#F2F8F9"
    >
      <GridItem>
        <Box width="700px" height="600px" position="relative">
          <Box position="absolute" left="-100px" top="40px">
            <Image src="/images/IxiIcon_small.webp" alt="" height="528px" width="auto" filter={"opacity(0.6)"}/>
          </Box>
          <CardContainer>
            <LogoContainer>
              <LogoFull width="150px" height="50px" />
            </LogoContainer>
            {children}
          </CardContainer>
        </Box>
      </GridItem>
    </Grid>
  );
}
