
/*
  Colour palette
  https://www.figma.com/file/CNdRNb3JOu1KfCghQJmDne/Ixico-design-system?node-id=12%3A2
*/

const standardColours = {
  yellow: "#FFC941",
  darkOrange: "#EB8A00",
  lightRed: "#EF878D",
  lightPurple: "#A26CBB",
  darkPurple: "#713ADD",
  lightBlue: "#4B7CF2",
  teal: "#29B5C3",
  lightGreen: "#81D2BC",
  green: "#00B886",
  blue: "#0040DA",
};

const notificationColours = {
  red: "#F24B4B",
  orange: "#FEB751",
  green: "#DCF0D7"
};

const greys = {
  100: "#FFFFFF",
  200: "#F3F3F3",
  300: "#EDEDED",
  400: "#EAE9E9",
  500: "#C0BEBE",
  600: "#9F9D9D",
  700: "#808184",
  800: "#777777",
  900: "#000000",
  1000: "#ADADAD",
  1100: "#C4C4C4"
};

const ixicoGreens = {
  100: "#F1F8F9",
  200: "#E1F2F4",
  300: "#96CED7",
  400: "#2CB4C3",
  500: "#41B6C2",
  600: "#2E9FAE",
  700: "#1A8493"
};

const templateColours = {
  100: "#FBFBFB",
  200: "#F9F9F9",
  300: "#F3F4F5"
};

const colors = {
  mainLayout: templateColours,
  highlight: ixicoGreens,
  body: greys,
  tiles: {
    100: standardColours.yellow,
    200: standardColours.darkOrange,
    300: standardColours.lightRed,
    400: standardColours.lightPurple,
    500: standardColours.darkPurple,
    600: standardColours.lightBlue,
    700: ixicoGreens[400],
    800: standardColours.lightGreen,
    900: standardColours.darkPurple,
    1000: notificationColours.orange
  },
  notifications: {
    100: notificationColours.red,
    200: notificationColours.orange,
    300: notificationColours.green,
    400: ixicoGreens[400],
    500: greys[300]
  },
  labelBackground: {
    100: standardColours.blue,
    200: standardColours.lightGreen,
    300: standardColours.lightPurple,
    400: standardColours.darkOrange,
    500: ixicoGreens[400],
    600: standardColours.darkPurple,
    700: standardColours.lightRed,
    800: standardColours.green
  },
  error: {
    100: notificationColours.red,
  },
  toast: {
    success: notificationColours.green,
    info: ixicoGreens[400],
    warning: notificationColours.orange,
    error: notificationColours.red,
  },
  standardColours: standardColours
};

export { colors };
