/**
 * It replaces the placeholders in the template string with
 * the values in the data object, example:
 * template string: "Hello %name% %surname% how are you"
 * data obj: {name: "Michey", surname: "Mouse"}
 *
 * returns: "Hello Michey Mouse how are you"
 *
 * @param linkString
 * @param template
 * @returns
 */
export function applyTemplate(data: Record<string, string>, template: string): string {
  return template.replace(/%(\w*)%/g, function(m, key) {return data.hasOwnProperty(key) ? data[key] : "";});
}
