import { createIcon } from "@chakra-ui/react";

export const EditIcon = createIcon({
  displayName: "EditIcon",
  path: (
    <svg viewBox="0 0 17 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.8286 4.1787L12.8213 0.171362C12.7116 0.0616465 12.5627 0 12.4075 0C12.2523 0 12.1034 0.0616465 11.9937 0.171362L0.297114 11.8678C0.191768 11.9733 0.130511 12.1149 0.125985 12.2638L0.000271785 16.397C-0.00464437 16.5583 0.0573147 16.7145 0.171401 16.8286C0.281351 16.9385 0.43024 17 0.585216 17C0.591146 17 0.597077 16.9999 0.603086 16.9997L4.73609 16.8738C4.88505 16.8693 5.02668 16.8081 5.13211 16.7028L16.8286 5.0064C17.0571 4.77784 17.0571 4.40726 16.8286 4.1787ZM4.46843 15.711L1.18912 15.8108L1.28893 12.5314L8.39971 5.4206L11.5796 8.59992L4.46843 15.711ZM12.4073 7.7723L9.22734 4.59306L12.4074 1.41295L15.587 4.59259L12.4073 7.7723Z" fill="currentColor"/>
    </svg>
  ),
});
