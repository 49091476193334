import { useCallback, useMemo } from "react";
import { getQueryState, getQueryStateAll } from "lib/common";
import { useQueries } from "react-query";
import { useMsal } from "@azure/msal-react";
import { IUseContextQueriesProps, IUseContextQueriesResult } from "./interfaces";
import { IContext } from ".";
import { usePermissionContext } from "../use-permission-context";
import { useContextQueryKey } from "./useContextQueryKey";

/**
 * Retrieves an item with context type and context uuid headers.
 * @returns The state, data or error associated with the query.
 */
export function useContextQueries<TRequest, TResponse>({
  enabled,
  permission,
  queryKey,
  queryFn,
  requestsWithContext,
  appId,
  studySiteId,
  subgroupId,
  studyId,
  cacheTime,
  staleTime
}: IUseContextQueriesProps<TRequest, TResponse>): IUseContextQueriesResult<TResponse> {
  const { instance } = useMsal();
  const { permissionWithContext } = usePermissionContext({ permission, appId, studySiteId, subgroupId, studyId });

  // query is only executed if a matching permission context is found
  const _enabled = enabled && !!permissionWithContext;

  const context: IContext = useMemo(() => ({
    contextType: permissionWithContext?.context_type,
    contextUuid: permissionWithContext?.context_uuid
  }), [permissionWithContext]);

  // apply context to request
  const requests = useMemo(() => requestsWithContext.map(requestWithContext => requestWithContext(context)), [context, requestsWithContext]);

  // apply context to query key array and ensure values are distinct
  const _queryKey = useContextQueryKey(queryKey, context, appId);

  const queriesMap = useMemo(() => {
    return requests.map((request, index) => ({
      queryKey: [..._queryKey, index],
      queryFn: async () => await queryFn(instance, request),
      staleTime,
      cacheTime,
      enabled: _enabled
    }));
  }, [_enabled, _queryKey, cacheTime, instance, queryFn, requests, staleTime]);

  const queries = useQueries(queriesMap);

  const refetch = useCallback(() => {
    queries.forEach((query) => query.refetch());
  }, [queries]);

  const { state, data, errors } = useMemo(() => ({
    state: getQueryStateAll(queries.map((query) => getQueryState(query.status))),
    data: queries.map((query) => query.data),
    errors: queries.map((query) => query.error)
  }), [queries]);

  return {
    state,
    data,
    errors,
    hasPermissionContext: !!permissionWithContext,
    refetch
  };
}
