import { createIcon } from "@chakra-ui/react";

export const Delete = createIcon({
  displayName: "Delete",
  viewBox: "0 0 30 30",
  path: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.0147 12.5H12.6912V20H14.0147V12.5Z" fill="currentColor"/>
      <path d="M17.9853 12.5H16.6617V20H17.9853V12.5Z" fill="currentColor"/>
      <path d="M7.39703 8.75V10H8.72056V22.5C8.72056 22.8315 8.86001 23.1495 9.10822 23.3839C9.35643 23.6183 9.69307 23.75 10.0441 23.75H20.6323C20.9833 23.75 21.32 23.6183 21.5682 23.3839C21.8164 23.1495 21.9559 22.8315 21.9559 22.5V10H23.2794V8.75H7.39703ZM10.0441 22.5V10H20.6323V22.5H10.0441Z" fill="currentColor"/>
      <path d="M17.9853 6.25H12.6912V7.5H17.9853V6.25Z" fill="currentColor"/>
    </svg>
  ),
});
