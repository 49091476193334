import { createIcon } from "@chakra-ui/react";

export const SubgroupIcon = createIcon({
  displayName: "SubgroupIcon",
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" version="1.1">
      <g id="surface1">
        <path fill="none" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" stroke="rgb(0%,0%,0%)" strokeOpacity="1" strokeMiterlimit="10" d="M 217.12 218.6 L 184.08 196.8 L 133 196.8 L 133 347.2 L 335.8 347.2 L 335.8 218.6 Z M 217.12 218.6 " transform="matrix(0.0976562,0,0,0.0976562,0,0)" />
        <path fill="none" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" stroke="rgb(0%,0%,0%)" strokeOpacity="1" strokeMiterlimit="10" d="M 355.8 327.28 L 355.8 198.72 L 237.12 198.72 L 204.08 176.8 L 152.88 176.8 " transform="matrix(0.0976562,0,0,0.0976562,0,0)" />
        <path fill="none" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" stroke="rgb(0%,0%,0%)" strokeOpacity="1" strokeMiterlimit="10" d="M 375.68 307.4 L 375.68 178.8 L 257 178.8 L 224 156.88 L 172.88 156.88 " transform="matrix(0.0976562,0,0,0.0976562,0,0)" />
      </g>
    </svg>
  ),
});
