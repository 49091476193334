import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useQuery, UseQueryResult } from "react-query";
import apis from "../../config/apis.json";
import { getAccessTokenClaims, IIdTokenClaims } from "../../services/msal";

export interface IUseTokenClaimsQueryResult {
  query: UseQueryResult<IIdTokenClaims, unknown>;
  userId: string | undefined;
}

export function useTokenClaimsQuery(): IUseTokenClaimsQueryResult {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const query = useQuery(
    ["getTokenClaims"],
    async () => await getAccessTokenClaims(instance, apis.security.scope),
    { enabled: isAuthenticated }
  );

  const userId = query.data?.extension_oasis_user_id;

  return {
    query,
    userId
  };
}
