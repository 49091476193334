import { useMemo, useRef } from "react";
import { useUser } from "lib/user";
import { useReferenceDataQuery, IQuery, RefDataQueryFn, ILocalisedText } from ".";
import { defaultLang } from "lib/i18n";

/**
 * Retrieves localised reference data.
 *
 * @param getReferenceData The reference data request function for the relevant service.
 * @param refTypeOid The reference data type oid.
 * @param localisedItemsSelector Function which retrieves the array of localised items from the reference data item.
 * @param oidSelector Function which retrieves the reference data item's oid.
 * @returns The query's data, state and error.
 */
export function useReferenceData<TData, TRefTypeOid>(
  getReferenceData: RefDataQueryFn<TData, TRefTypeOid>,
  refTypeOid: TRefTypeOid,
  localisedItemsSelector: (item: TData) => ILocalisedText[],
  oidSelector: (item: TData) => string
): IQuery<Record<string, string>> {
  const { userResult } = useUser();
  const userLang = userResult.data?.user_profile?.user_lang;

  const query = useReferenceDataQuery<TData, TRefTypeOid>(getReferenceData, refTypeOid);
  const referenceData = query.data?.data;

  // cache selector functions on first render
  const itemsSelectorRef = useRef(localisedItemsSelector);
  const oidSelectorRef = useRef(oidSelector);

  const data = useMemo(() => (
    referenceData?.reduce((previous, current) => {
      const localisedTextItems = itemsSelectorRef.current(current);
      const oid = oidSelectorRef.current(current);

      // get text for user's language
      const localisedStudyStatus = localisedTextItems
        .find(value => value.iso_code.toLowerCase() === userLang?.toLowerCase())
        ?.text;

      // get fallback text (en-gb)
      const fallback = localisedTextItems
        .find(value => value.iso_code.toLowerCase() === defaultLang?.toLowerCase())
        ?.text ?? "";

      // update object with oid/value pair
      return {
        ...previous,
        [oid]: localisedStudyStatus ?? fallback
      };
    }, {}) ?? {}
  ), [referenceData, userLang, itemsSelectorRef, oidSelectorRef]);

  return {
    data,
    state: query.state,
    error: query.error
  };
}
