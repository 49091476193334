import { useUser } from ".";

/**
 * It returns the user primary role.
 * If the user has multiple roles, it will
 * return the first one in the list
 */
export function useUserPrimaryRole(): string | undefined {
  const user = useUser();

  const userRoles = user.userResult.data.user_roles;

  if (userRoles && userRoles.length > 0) {
    return userRoles[0].role_oid;
  }

  return undefined;
}
