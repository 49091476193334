// see "styles/themes/buttons.ts" for implementation of these sizes/variants

export const sizes = {
  small: "sm",
  smallwithicon: "smicon",
  medium: "md",
};

export const variants = {
  primary: "primary",
  secondary: "secondary",
  ghost: "ghost",
  icon: "icon"
};

export const ButtonStyles = {
  sizes,
  variants,
};
