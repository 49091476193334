import { createIcon } from "@chakra-ui/react";

export const PetIcon = createIcon({
  displayName: "PetIcon",
  path: (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.00595 21.2301L7.36239 11.3651H15.4541L16.8106 21.2301H6.00595Z" stroke="currentColor" />
      <path d="M16.7693 19.4436C18.4137 18.2834 19.6569 16.6229 20.3178 14.7038C20.9787 12.7848 21.0228 10.7074 20.4437 8.77436C19.8647 6.8413 18.6926 5.15347 17.0983 3.95669C15.504 2.75991 13.5707 2.11668 11.58 2.12069C9.5893 2.1247 7.64513 2.77574 6.03066 3.97899C4.41619 5.18224 3.21575 6.87485 2.60417 8.81031C1.99258 10.7458 2.0018 12.823 2.63048 14.7394C3.25916 16.6559 4.47447 18.3115 6.09939 19.4651L6.78464 18.472C5.36589 17.4648 4.30479 16.0193 3.75588 14.346C3.20697 12.6727 3.19892 10.8591 3.7329 9.16919C4.26688 7.47931 5.31501 6.00146 6.72463 4.95088C8.13425 3.90031 9.83174 3.33187 11.5699 3.32837C13.308 3.32487 14.996 3.88648 16.388 4.93141C17.78 5.97634 18.8033 7.45001 19.3089 9.1378C19.8145 10.8256 19.776 12.6393 19.199 14.3149C18.6219 15.9905 17.5365 17.4403 16.1007 18.4533L16.7693 19.4436Z" fill="currentColor" />
      <path d="M16.3725 16.9075C17.3853 15.901 18.084 14.6133 18.3793 13.2087C18.6746 11.804 18.5532 10.3461 18.0306 9.0208C17.5079 7.6955 16.6077 6.56294 15.4448 5.76757C14.2819 4.9722 12.909 4.55007 11.5012 4.55504C10.0934 4.56001 8.71449 4.99184 7.54041 5.79545C6.36632 6.59907 5.45026 7.73804 4.90907 9.06709C4.36788 10.3961 4.2261 11.855 4.50179 13.2577C4.77749 14.6603 5.45818 15.9431 6.45704 16.9424L6.92688 16.4558C6.02201 15.5505 5.40536 14.3884 5.15561 13.1177C4.90585 11.847 5.03429 10.5254 5.52457 9.3214C6.01484 8.1174 6.84471 7.08559 7.90832 6.35758C8.97194 5.62958 10.2211 5.23837 11.4965 5.23387C12.7718 5.22937 14.0156 5.61178 15.069 6.33232C16.1225 7.05285 16.938 8.07886 17.4115 9.27946C17.885 10.4801 17.995 11.8008 17.7274 13.0733C17.4599 14.3458 16.827 15.5123 15.9094 16.4241L16.3725 16.9075Z" fill="currentColor" />
    </svg>
  ),
});
