import { ChakraProvider } from "@chakra-ui/react";
import { ErrorComponent } from "components/error-component/ErrorComponent";
import { Fonts } from "components/fonts";
import { theme } from "styles/theme";

/**
 * Renders a full `<body>` error message when an error occurs in the application root.
 */
export function RootBoundaryError() {
  return (
    <ChakraProvider theme={theme}>
      <Fonts/>
      <ErrorComponent errorCode={"Please refresh the page and try again."} />
    </ChakraProvider>
  );
}
