import { createIcon } from "@chakra-ui/react";

export const DeleteIcon = createIcon({
  displayName: "DeleteIcon",
  path: (
    <svg viewBox="-5 0 31 23" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3966 7.60835C13.1249 7.60835 12.9048 7.8285 12.9048 8.10015V17.3952C12.9048 17.6667 13.1249 17.887 13.3966 17.887C13.6682 17.887 13.8884 17.6667 13.8884 17.3952V8.10015C13.8884 7.8285 13.6682 7.60835 13.3966 7.60835Z" fill="currentColor" />
      <path d="M7.59337 7.60835C7.32172 7.60835 7.10156 7.8285 7.10156 8.10015V17.3952C7.10156 17.6667 7.32172 17.887 7.59337 17.887C7.86501 17.887 8.08517 17.6667 8.08517 17.3952V8.10015C8.08517 7.8285 7.86501 7.60835 7.59337 7.60835Z" fill="currentColor" />
      <path d="M3.36408 6.2519V18.3689C3.36408 19.085 3.6267 19.7576 4.08546 20.2402C4.5421 20.7241 5.1776 20.9988 5.84269 21H15.1477C15.813 20.9988 16.4485 20.7241 16.905 20.2402C17.3637 19.7576 17.6263 19.085 17.6263 18.3689V6.2519C18.5383 6.00984 19.1292 5.12883 19.0072 4.19306C18.885 3.25748 18.088 2.55763 17.1443 2.55743H14.6263V1.94268C14.6292 1.42571 14.4248 0.929301 14.0589 0.5641C13.6929 0.199091 13.1957 -0.0041618 12.6787 6.46187e-05H8.31169C7.79472 -0.0041618 7.29754 0.199091 6.93157 0.5641C6.5656 0.929301 6.36119 1.42571 6.36407 1.94268V2.55743H3.84609C2.90244 2.55763 2.10538 3.25748 1.9832 4.19306C1.86121 5.12883 2.45214 6.00984 3.36408 6.2519ZM15.1477 20.0164H5.84269C5.00182 20.0164 4.34769 19.2941 4.34769 18.3689V6.29513H16.6427V18.3689C16.6427 19.2941 15.9886 20.0164 15.1477 20.0164ZM7.34768 1.94268C7.34441 1.6866 7.44508 1.44012 7.62681 1.25935C7.80836 1.07857 8.05541 0.97925 8.31169 0.983668H12.6787C12.935 0.97925 13.1821 1.07857 13.3636 1.25935C13.5453 1.43993 13.646 1.6866 13.6427 1.94268V2.55743H7.34768V1.94268ZM3.84609 3.54104H17.1443C17.6333 3.54104 18.0296 3.93736 18.0296 4.42628C18.0296 4.9152 17.6333 5.31152 17.1443 5.31152H3.84609C3.35717 5.31152 2.96084 4.9152 2.96084 4.42628C2.96084 3.93736 3.35717 3.54104 3.84609 3.54104Z" fill="currentColor" />
      <path d="M10.495 7.60835C10.2233 7.60835 10.0032 7.8285 10.0032 8.10015V17.3952C10.0032 17.6667 10.2233 17.887 10.495 17.887C10.7666 17.887 10.9868 17.6667 10.9868 17.3952V8.10015C10.9868 7.8285 10.7666 7.60835 10.495 7.60835Z" fill="currentColor" />
    </svg>
  ),
});
