import { createIcon } from "@chakra-ui/react";

export const ChevronUpIcon = createIcon({
  displayName: "ChevronUpIcon",
  viewBox: "0 0 16 10",
  path: (
    <path
      d="M16 8.05837L14.8321 9.22626L8.00002 2.33575L1.16791 9.22626L3.05176e-05 8.05837L8.00002 -1.62125e-05L16 8.05837Z"
      fill="currentColor"/>
  ),
});
