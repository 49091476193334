import { createIcon } from "@chakra-ui/react";

export const MediumPriority = createIcon({
  displayName: "MediumPriority",
  viewBox: "0 0 18 22",
  path: (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="11" width="12" height="9" fill="#00B886" />
      <rect x="0.5" y="0.5" width="17" height="21" stroke="#00B886" />
    </svg>
  ),
});
