import { createIcon } from "@chakra-ui/react";

export const MenuIconSubjects = createIcon({
  displayName: "MenuIconSubjects",
  path: (
    <svg width="30" height="30" viewBox="3 3 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.1615 21.3272C22.1615 21.9193 21.9498 22.3135 21.6066 22.6047C21.2418 22.9143 20.6961 23.1326 20.0005 23.2743C18.8195 23.5149 17.3831 23.5052 16.0569 23.4963C15.8103 23.4946 15.5674 23.4929 15.3307 23.4929C15.0941 23.4929 14.8512 23.4946 14.6046 23.4963C13.2784 23.5052 11.842 23.5149 10.661 23.2743C9.96534 23.1326 9.41972 22.9143 9.05485 22.6047C8.71169 22.3135 8.5 21.9193 8.5 21.3272C8.5 20.7031 8.73666 19.9172 9.17587 19.0644C9.61101 18.2195 10.2255 17.3467 10.9332 16.5585C11.6413 15.7698 12.4311 15.0789 13.2095 14.5886C13.996 14.0934 14.7278 13.83 15.3307 13.83C15.9337 13.83 16.6655 14.0934 17.4519 14.5886C18.2304 15.0789 19.0201 15.7698 19.7283 16.5585C20.4359 17.3467 21.0505 18.2195 21.4856 19.0644C21.9248 19.9172 22.1615 20.7031 22.1615 21.3272Z" stroke="currentColor" />
      <circle cx="15.3307" cy="8.66502" r="4.16502" stroke="currentColor" />
    </svg>
  ),
});
