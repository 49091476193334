import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { DEFAULT_SPACING } from "constants/spacing";

export const ContentCard: React.FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Box
      data-testid="content-card"
      bg="body.100"
      padding={DEFAULT_SPACING}
      {...rest}
    >
      {children}
    </Box>
  );
};
