import { createIcon } from "@chakra-ui/react";

export const MriIcon = createIcon({
  displayName: "MriIcon",
  path: (
    <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.6913 18.3982C13.3084 18.9957 15.0678 19.0937 16.7413 18.6796C18.4148 18.2654 19.9253 17.3581 21.0771 16.0754C22.2288 14.7926 22.9688 13.1934 23.201 11.4851C23.4332 9.77687 23.1469 8.03823 22.3794 6.49455C21.6118 4.95088 20.3982 3.67334 18.896 2.8275C17.3937 1.98167 15.6721 1.60654 13.9541 1.75073C12.2362 1.89493 10.6012 2.55179 9.26094 3.6362C7.92072 4.72061 6.93711 6.18256 6.43757 7.83259L7.74585 8.22866C8.16604 6.84073 8.99342 5.611 10.1208 4.69884C11.2481 3.78669 12.6234 3.23416 14.0685 3.11287C15.5135 2.99158 16.9617 3.30713 18.2253 4.0186C19.4889 4.73008 20.5097 5.80469 21.1554 7.10317C21.8011 8.40164 22.0418 9.86411 21.8465 11.301C21.6512 12.738 21.0288 14.0831 20.06 15.1621C19.0911 16.2411 17.8205 17.0043 16.4129 17.3527C15.0052 17.7011 13.5253 17.6186 12.1651 17.116L11.6913 18.3982Z" fill="currentColor" />
      <circle cx="13.0217" cy="11.2245" r="2.50501" stroke="currentColor" />
      <path d="M0.5 12.2229C0.5 11.3944 1.17157 10.7229 2 10.7229H5.20868C7.58792 10.7229 9.51668 12.6516 9.51668 15.0309C9.51668 15.4186 9.20239 15.7329 8.81468 15.7329H2C1.17157 15.7329 0.5 15.0613 0.5 14.2329V12.2229Z" stroke="currentColor" />
      <line y1="17.7362" x2="10.0167" y2="17.7362" stroke="currentColor" />
    </svg>
  ),
});
