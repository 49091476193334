const typography = {
  fonts: {
    body: `Roboto Regular,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
    heading: `Roboto Bold,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
  },
  textStyles: {
    body: {
      fontSize: ["16px"],
      fontWeight: "normal",
    },
    bodyBold: {
      fontSize: ["16px"],
      fontWeight: "bold",
    },
    cardBody: {
      fontSize: ["18px"],
      fontWeight: "normal",
    }
  }
};

export { typography };
