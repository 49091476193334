import apis from "../../config/apis.json";
import { getAccessToken } from "../msal";
import { IPublicClientApplication } from "@azure/msal-browser";
import axios from "axios";
import {
  IGetUserPermissionsRequest,
  IGetUserRequest,
  IGetUsersRequest,
  IUser,
  IUserPermission,
} from "./interfaces";
import { QueryString } from "lib/common";

/** Retrieves permissions for the current user.
 *
 * @param instance The MSAL client instance.
 * @param request The request.
 * @returns The list of user permissions.
 */
export async function getUserPermissions(instance: IPublicClientApplication, request: IGetUserPermissionsRequest): Promise<IUserPermission[] | undefined> {
  if (!request.userId) {
    return;
  }

  const { baseUrl, scope } = apis.security;

  const requestUrl = `${baseUrl}/users/${request.userId}/permissions?app_id=${request.appId}`;
  const accessToken = await getAccessToken(instance, scope);

  const result = await axios.get(
    requestUrl, {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }
    }
  );

  return result.data;
}

/** Retrieves permissions for the current user.
 *
 * @param accessToken The security service access token.
 * @param request The request.
 * @returns The list of user permissions.
 */
export async function getUserPermissionsWithToken(accessToken: string, request: IGetUserPermissionsRequest): Promise<IUserPermission[]> {
  const { baseUrl } = apis.security;

  const requestUrl = `${baseUrl}/users/${request.userId}/permissions?app_id=${request.appId}`;

  const result = await axios.get(
    requestUrl, {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }
    });

  return result.data;
}

/**
 * Retrieves the user from the security service, using the ID specified in the `request`.
 *
 * @param instance msal instance
 * @param request IGetUserRequest object containing the Ixico User ID to retrieve
 */
export async function getUser(instance: IPublicClientApplication, request: IGetUserRequest): Promise<IUser | undefined> {
  if (!request.userId) {
    return;
  }

  const { scope, baseUrl } = apis.security;

  const requestUrl = `${baseUrl}/users/${request.userId}`;
  const accessToken = await getAccessToken(instance, scope);

  const result = await axios.get(
    requestUrl, {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }
    });

  return result.data;
}

/**
 * Retrieves a list of users.
 *
 * @param instance The msal instance.
 * @param request The request details.
 * @returns The retrieved users.
 */
export async function getUsers(instance: IPublicClientApplication, request: IGetUsersRequest): Promise<IUser[]> {
  const { scope, baseUrl } = apis.security;

  const queryString = QueryString.from(request.queryParams);
  const requestUrl = `${baseUrl}/users${queryString}`;
  const accessToken = await getAccessToken(instance, scope);

  const result = await axios.get(
    requestUrl, {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }
    });

  return result.data;
}
