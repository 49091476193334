import React, { createContext } from "react";
import { IUserResult } from "./interfaces";
import { useTokenClaimsQuery } from "./useTokenClaimsQuery";
import { defaultUserContext } from "./defaultUserContext";
import { useUserQueryResult } from "./useUserQueryResult";
import { useUserPermissionQueryResult } from "./useUserPermissionQueryResult";
import { IIdTokenClaims } from "../../services/msal";
import { IChildren } from "lib/common/interfaces";

// create and export the context for consumption by the useUserProfile() hook
export const UserContext = createContext<IUserResult>(defaultUserContext);

const { Provider } = UserContext;

/** Retrieves the user's profile from the security service and passes their
 * `user_lang` to {@link i18next.changeLanguage} so that we can apply text translations for that
 * language.
 */
export const UserProvider: React.FC<IChildren> = ({ children }) => {
  const { userId, query: userTokenClaimsQuery } = useTokenClaimsQuery();
  const { userResult } = useUserQueryResult({ userId });
  const { userPermissionResult } = useUserPermissionQueryResult({ userId });

  const userTokenClaimsResult = {
    status: userTokenClaimsQuery.status,
    isError: userTokenClaimsQuery.isError,
    isLoading: userTokenClaimsQuery.isLoading,
    isSuccess: userTokenClaimsQuery.isSuccess,
    error: userTokenClaimsQuery.error,
    data: userTokenClaimsQuery.data as IIdTokenClaims
  };

  const result: IUserResult = {
    userTokenClaimsResult,
    userResult,
    userPermissionResult
  };

  return (
    <Provider value={result}>{children}</Provider>
  );
};
