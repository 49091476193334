const baseStyles = {
  borderRadius: "5px",
  border: "1px solid",
  fontStyle: "normal",
};

const extendedStyles = {
  ...baseStyles,
  fontWeight: "normal",
  borderColor: "highlight.600",
};
const Button = {
  sizes: {
    sm: {
      fontSize: "16px",
      px: "24px",
      py: "7px",
    },
    smicon: {
      fontSize: "16px",
      pr: "10px",
      pl: "24px",
      py: "7px",
    },
    md: {
      fontSize: "18px",
      px: "47px",
      py: "12px",
    },

  },
  variants: {
    primary: {
      ...extendedStyles,
      bg: "highlight.600",
      textColor: "body.100",
      _hover: {
        bg: "highlight.300",
        borderColor: "highlight.300",
        _disabled: { bg: "body.500", borderColor: "body.500" },
      },
      _active: { bg: "highlight.700", borderColor: "highlight.700" },
      _focus: { bg: "highlight.700", borderColor: "highlight.700" },
      _disabled: { bg: "body.500", borderColor: "body.500" },
    },
    secondary: {
      ...extendedStyles,
      bg: "transparent",
      textColor: "highlight.600",
      _hover: {
        bg: "transparent",
        borderColor: "highlight.400",
        textColor: "highlight.400",
        _disabled: { borderColor: "body.500", textColor: "body.500" },
      },
      _active: { borderColor: "highlight.700", textColor: "highlight.700" },
      _focus: { borderColor: "highlight.700", textColor: "highlight.700" },
      _disabled: { borderColor: "body.500", textColor: "body.500" },
    },
    ghost: {
      ...baseStyles,
      border: "none",
      textColor: "highlight.600",
      fontWeight: "medium",
      _hover: {
        bg: "none",
        textDecoration: "underline",
        textColor: "highlight.400",
        _disabled: { textColor: "body.500", textDecoration: "none", },
      },
      _active: { textColor: "highlight.700" },
      _focus: { textColor: "highlight.700" },
      _disabled: { textColor: "body.500" },

    },
    icon: {
      padding: 0,
      bg: "transparent",
      _focus: { bg: "transparent" },
      _active: { bg: "transparent" },
      _hover: { bg: "transparent" }
    }
  },
};

export { Button };
