import { colors } from "./colors";

const Radio = {
  baseStyle: {
    label: {
      color: "body.900",
      _checked: { color: "highlight.600", _disabled: { color: "body.600" } },
      _disabled: { color: "body.600", opacity: 1, _hover: { color: "body.600", opacity: 1, cursor: "not-allowed" } },
      _hover: { color: "highlight.600" },
    },
    control: {
      w: "100%",
      transitionProperty: "box-shadow",
      transitionDuration: "normal",
      border: "1px solid",
      borderColor: "body.900",
      color: "mainLayout.100",
      borderRadius: "full",
      _hover: {
        bg: "highlight.200",
        borderColor: "highlight.600",
        borderRadius: "50%"
      },
      _checked: {
        bg: "body.100",
        borderColor: "highlight.600",
        position: "relative",
        border: 0,
        _hover: {
          bg: "body.100",
          borderColor: "highlight.600",
        },
        _disabled: {
          borderColor: "body.600",
          bg: "body.500",
          color: "body.200",
        },
        _before: {
          content: `""`,
          display: "inline-block",
          pos: "absolute",
          w: "unset",
          h: "unset",
          border: "2px solid white",
          borderRadius: "50%",
          bg: "highlight.600",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          outline: `1px solid ${colors.highlight[600]}`
        },
      },
      _disabled: {
        bg: "body.500",
        borderColor: "body.600",
        _hover: {
          bg: "body.500",
          borderColor: "body.600"
        }
      },
      _focus: {
        boxShadow: "checkbox",
      },
      _invalid: {
        borderColor: "notifications.100",
        _before: {
          outline: `1px solid ${colors.notifications[100]}`
        }
      },
    }
  }
};

export { Radio };
