/**
 * Styles used be the profile-badge.tsx
 */

export const ProfileBadgeGreetingCmp = {
  baseStyle: {
    color: "#FFFFFF",
    borderRadius: "10px 0 0 10px",
    backgroundColor: "highlight.600",
    paddingLeft: "17px",
    paddingRight: "20px",
    fontSize: "14px",
    lineHeight: "16px",
    height: "35px",
    display: "flex",
    alignItems: "center",
  }
};

export const ProfileBadgeMenuIconCmp = {
  baseStyle: {
    fill: "highlight.600",
  }
};

export const ProfileBadgeAvatarCmp = {
  baseStyle: {
    color: "#FFFFFF",
    backgroundColor: "highlight.300",
    pos: "relative",
    right: "10px",
    w: "37px",
    h: "37px",
    fontSize: "14px",
    borderRadius: "50%",
    border: "2px solid #FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
};

export const ProfileBadgeMenuAvatarCmp = {
  baseStyle: {
    color: "#FBFBFB",
    backgroundColor: "highlight.300",
    w: "52px",
    h: "52px",
    fontSize: "24px",
    lineHeight: "28.13px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
};

export const MenuBadgeCmp = {
  baseStyle: {
    color: "#000000",
    fontSize: "20px",
    lineHeight: "23.44px",
    fontWeight: 500,
    marginLeft: "10px",
  }
};

export const Menu = {
  parts: ["item", "divider", "list"],
  baseStyle: {
    item: {
      color: "#000000",
      fontSize: "16px",
      lineHeight: "18.75px",
      fontWeight: 400,
    },
    divider: {
      position: "relative",
      left: "17px",
      width: "calc(100% - 34px)",
    },
    list: {
      boxShadow: "0px 4px 4px rgba(227, 227, 227, 0.25)",
    }
  },
};
