import { useState, useCallback, SetStateAction } from "react";

export type UseNodeRefCallback<TNode, TResult> = (newNode: TNode) => SetStateAction<TResult | undefined>;
export type IUseStateRefResult<TNode, TResult> = (TResult | ((newNode: TNode) => void) | undefined)[];

/**
 * Hook which allows reacting to node updates (e.g. height change) with useEffect.
 * Adapted from https://medium.com/welldone-software/usecallback-might-be-what-you-meant-by-useref-useeffect-773bc0278ae
 * - Vitali Zaidman
 *
 * @param processNode Function which returns the desired value from an HTMLElement.
 * @returns
 */
export function useStateRef<TNode, TResult>(processNode: UseNodeRefCallback<TNode, TResult>): IUseStateRefResult<TNode, TResult> {
  const [node, setNode] = useState<TResult | undefined>(undefined);
  const setRef = useCallback((newNode: TNode) => {
    setNode(processNode(newNode));
  }, [processNode]);

  return [node, setRef];
}
