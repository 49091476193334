import { createIcon } from "@chakra-ui/react";

export const ChevronDownIcon = createIcon({
  displayName: "ChevronDownIcon",
  viewBox: "0 0 16 10",
  path: (
    <path
      d="M0 1.16788L1.16788 0L8.00001 6.89051L14.8321 0L16 1.16788L8.00001 9.22627L0 1.16788Z" fill="currentColor"
    />
  ),
});
