import { FieldValues, UseFormGetValues, UseFormReset } from "react-hook-form";
import { GroupedFieldValues } from "./interfaces";

/**
 * Clears fields (including grouped form fields).
 * @param dataOrFieldGroup The root form data, or field group.
 * @param replacementDataOrGroup The replacement form data, or field group.
 */
function clearFieldsRecursive(dataOrFieldGroup: GroupedFieldValues<string>, replacementDataOrGroup: GroupedFieldValues<string>) {
  const properties = Object.keys(dataOrFieldGroup);
  for (const property of properties) {
    const value = dataOrFieldGroup[property];
    if (typeof value === "object") {
      replacementDataOrGroup[property] = {};
      clearFieldsRecursive(value, replacementDataOrGroup[property] as GroupedFieldValues<string>);
    }
    else {
      // set "" as empty field value
      replacementDataOrGroup[property] = "";
    }
  }
}

/**
 * Clears all fields on the form by setting their value to "".
 *
 * This avoids some edge cases with `UseFormReturn.reset()` which can result in the form field values not being cleared.
 * e.g. Fields may not be cleared with `UseFormReturn.reset()` if there are validation errors on the form.
 * @param getValues The `UseFormReturn.getValues` method, used to retrieve all form fields and their values.
 * @param reset The `UseFormReturn.reset` method, used to reset/clear form fields.
 */
export function clearFormFields<TFieldValues extends FieldValues>(
  getValues: UseFormGetValues<TFieldValues>,
  reset: UseFormReset<TFieldValues>
): void {
  // retrieve all form field names
  const fieldValues = getValues();

  // assign the value "" to all form fields and update the form
  const nullFields: GroupedFieldValues<string> = {};

  clearFieldsRecursive(fieldValues, nullFields);
  reset(nullFields as TFieldValues);
}
