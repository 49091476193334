import { createIcon } from "@chakra-ui/react";

export const LogoSmall = createIcon({
  displayName: "LogoSmall",
  viewBox: "0 0 60 50",
  path: (
    <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_170:616)">
        <path d="M33.8686 30.4884C33.8686 31.703 33.5443 32.8975 33.2606 33.2416L23.3696 45.8743C21.9913 47.4534 20.532 48.2631 19.6604 47.9392C19.0321 47.7165 18.6875 46.7853 18.6875 45.4289V2.59128C18.6875 1.21465 19.0321 0.303639 19.6604 0.0809479C20.532 -0.242966 21.9913 0.566819 23.3696 2.1459L33.2606 14.7785C33.5443 15.1025 33.8686 16.3171 33.8686 17.5318V30.4884Z" fill="#2E9FAE"/>
        <path d="M15.7891 11.8026L11.3301 8.23957C9.22216 6.72123 7.19531 7.47028 7.19531 10.1831V37.817C7.19531 40.5297 9.22216 41.2585 11.3301 39.7604L15.7891 36.1974V11.8026Z" fill="#41B6C2"/>
        <path d="M40.2126 8.23955L34.9226 12.8351L36.0373 14.2724C36.3414 14.6166 36.6859 15.892 36.6859 17.1674V30.8326C36.6859 32.108 36.3414 33.3834 36.0373 33.7275L34.9226 35.1649L40.2126 39.7604C42.3206 41.2585 44.3474 40.5297 44.3474 37.8169V10.183C44.3474 7.47026 42.3206 6.74145 40.2126 8.23955Z" fill="#41B6C2"/>
        <path d="M0.466175 16.803C0.162148 17.046 0 17.4306 0 17.8962V30.124C0 30.5694 0.162148 30.954 0.466175 31.197C0.749934 31.4399 1.13503 31.5209 1.52014 31.4399L4.58068 30.8326V17.1877L1.52014 16.5803C1.13503 16.4994 0.749934 16.5803 0.466175 16.803Z" fill="#96CED7"/>
        <path d="M51.0766 16.803C50.7928 16.5601 50.4077 16.4791 50.0226 16.5601L46.9621 17.1674V30.8123L50.0226 31.4196C50.428 31.5006 50.7928 31.4196 51.0766 31.1767C51.3806 30.9338 51.5427 30.5491 51.5427 30.1037V17.8962C51.5427 17.4306 51.3603 17.046 51.0766 16.803Z" fill="#96CED7"/>
      </g>
      <defs>
        <clipPath id="clip0_170:616">
          <rect width="147.879" height="48" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
});
