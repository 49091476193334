/**
 * Force a page reload when a previously loaded page is rehydrated from the
 * back/forward browser cache. This prevents buggy behaviour with MSAL.
 * See https://web.dev/bfcache for details.
 * @param event The page transition event.
 */
export function onBackForwardCacheLoad(event: PageTransitionEvent) {
  if (event.persisted) {
    window.location.reload();
  }
}

/**
 * Attach the `onBackForwardCacheLoad` event listener to the `pageshow` event.
 */
export function addBackForwardCacheLoadListener() {
  window.addEventListener("pageshow", onBackForwardCacheLoad);
}
