import { createIcon } from "@chakra-ui/react";

export const LogoFull = createIcon({
  displayName: "LogoFull",
  viewBox: "0 0 150 50",
  path: (
    <svg viewBox="0 0 150 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_170:616)">
        <path d="M33.8686 30.4884C33.8686 31.703 33.5443 32.8975 33.2606 33.2416L23.3696 45.8743C21.9913 47.4534 20.532 48.2631 19.6604 47.9392C19.0321 47.7165 18.6875 46.7853 18.6875 45.4289V2.59128C18.6875 1.21465 19.0321 0.303639 19.6604 0.0809479C20.532 -0.242966 21.9913 0.566819 23.3696 2.1459L33.2606 14.7785C33.5443 15.1025 33.8686 16.3171 33.8686 17.5318V30.4884Z" fill="#2E9FAE"/>
        <path d="M15.7891 11.8026L11.3301 8.23957C9.22216 6.72123 7.19531 7.47028 7.19531 10.1831V37.817C7.19531 40.5297 9.22216 41.2585 11.3301 39.7604L15.7891 36.1974V11.8026Z" fill="#41B6C2"/>
        <path d="M40.2126 8.23955L34.9226 12.8351L36.0373 14.2724C36.3414 14.6166 36.6859 15.892 36.6859 17.1674V30.8326C36.6859 32.108 36.3414 33.3834 36.0373 33.7275L34.9226 35.1649L40.2126 39.7604C42.3206 41.2585 44.3474 40.5297 44.3474 37.8169V10.183C44.3474 7.47026 42.3206 6.74145 40.2126 8.23955Z" fill="#41B6C2"/>
        <path d="M144.778 31.7638C143.764 32.695 142.731 33.3429 141.677 33.7275C140.623 34.1122 139.731 34.3753 139.021 34.4968C138.312 34.6385 137.562 34.6993 136.792 34.6993C135.333 34.6993 133.873 34.4361 132.414 33.93C130.955 33.4239 129.739 32.695 128.786 31.7638C127.813 30.8325 127.124 29.8406 126.698 28.7271C126.273 27.6339 125.989 26.7431 125.867 26.0346C125.746 25.326 125.665 24.5972 125.665 23.8077C125.665 22.3096 125.867 21.0341 126.273 19.9814C126.678 18.9489 127.063 18.1392 127.428 17.5926C127.793 17.046 128.34 16.3779 129.09 15.6288C129.84 14.8798 130.914 14.232 132.313 13.7258C133.711 13.2197 135.191 12.9565 136.792 12.9565C138.292 12.9565 139.751 13.1995 141.15 13.6853C142.548 14.1712 143.622 14.7988 144.413 15.5479C145.203 16.2969 145.771 16.965 146.136 17.5318C146.501 18.0987 146.886 18.8882 147.291 19.9004C147.696 20.9127 147.879 22.2083 147.879 23.8077C147.879 25.3058 147.615 26.7634 147.109 28.14C146.582 29.5166 145.811 30.7313 144.778 31.7638ZM134.927 29.8406C135.13 29.9418 135.414 30.0228 135.738 30.0835C136.062 30.1442 136.387 30.1645 136.711 30.1645C137.846 30.1645 138.88 29.8406 139.792 29.1725C140.724 28.5044 141.332 27.7149 141.636 26.7634C141.94 25.8119 142.082 24.8604 142.082 23.8684C142.082 22.4715 141.819 21.2568 141.312 20.2244C140.805 19.1919 139.974 18.4226 138.839 17.957C138.109 17.6938 137.42 17.5521 136.812 17.5521C136.123 17.5521 135.414 17.6938 134.664 17.9975C133.954 18.3416 133.367 18.7667 132.88 19.2931C132.414 19.8195 132.049 20.4673 131.786 21.2366C131.542 22.0059 131.401 22.8764 131.401 23.8886C131.401 25.2248 131.684 26.4395 132.252 27.5327C132.86 28.5854 133.731 29.3749 134.927 29.8406Z" fill="#808284"/>
        <path d="M101.221 19.7992L101.2 13.3615H97.8562C97.4914 13.3615 95.7685 13.4829 95.4848 15.0013V19.7992H95.4645V34.3146H101.221V19.7992Z" fill="#808284"/>
        <path d="M68.5885 19.7992L68.5682 13.3615H65.224C64.8389 13.3615 63.1363 13.4829 62.8525 15.0013V19.7992H62.8323V34.3146H68.5885V19.7992Z" fill="#808284"/>
        <path d="M93.0526 13.321H87.9449C86.141 13.4829 85.006 15.8111 84.2155 16.6613L81.986 19.698L78.966 15.4062C78.2566 14.4547 77.3445 13.4222 76.1487 13.321H71.1018L78.8646 23.8077L71.1829 34.3147H76.1689C77.4661 34.1729 78.4187 32.9178 79.1281 31.9258L81.9657 27.6137L84.1952 30.9743C84.1952 30.9743 84.1952 30.9743 84.2155 30.9945C84.9857 31.9055 86.1208 34.1527 87.9044 34.3349H92.8904L85.2289 23.9697L93.0526 13.321Z" fill="#808284"/>
        <path d="M109.734 23.8684C109.734 20.2041 111.537 18.2606 114.78 18.0379C114.922 18.0379 115.064 18.0177 115.226 18.0177H115.247C116.159 17.8962 119.016 18.0177 121.327 17.5926C122.827 17.3091 123.253 16.3779 123.354 15.4669V13.4626H115.895C114.334 13.4626 112.875 13.6448 111.537 13.989C110.2 14.3534 109.004 15.0215 107.97 15.9932C106.936 16.965 106.146 18.1594 105.619 19.5968C105.092 21.0341 104.829 22.4715 104.829 23.9089C104.829 25.3462 105.092 26.7836 105.619 28.221C106.146 29.6583 106.936 30.8528 107.97 31.8245C109.004 32.7963 110.2 33.4643 111.537 33.8287C112.875 34.1931 114.334 34.3551 115.895 34.3551H123.354V32.3509C123.253 31.4399 122.827 30.5086 121.327 30.2252C118.996 29.8001 116.159 29.9215 115.247 29.8001H115.226C115.084 29.8001 114.943 29.7798 114.78 29.7798C111.558 29.4761 109.734 27.5327 109.734 23.8684Z" fill="#808284"/>
        <path d="M0.466175 16.803C0.162148 17.046 0 17.4306 0 17.8962V30.124C0 30.5694 0.162148 30.954 0.466175 31.197C0.749934 31.4399 1.13503 31.5209 1.52014 31.4399L4.58068 30.8326V17.1877L1.52014 16.5803C1.13503 16.4994 0.749934 16.5803 0.466175 16.803Z" fill="#96CED7"/>
        <path d="M51.0766 16.803C50.7928 16.5601 50.4077 16.4791 50.0226 16.5601L46.9621 17.1674V30.8123L50.0226 31.4196C50.428 31.5006 50.7928 31.4196 51.0766 31.1767C51.3806 30.9338 51.5427 30.5491 51.5427 30.1037V17.8962C51.5427 17.4306 51.3603 17.046 51.0766 16.803Z" fill="#96CED7"/>
      </g>
      <defs>
        <clipPath id="clip0_170:616">
          <rect width="147.879" height="48" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
});
