import { MutableRefObject, useEffect, useRef, useState, useCallback } from "react";
import { IUseTimerResult } from "./interfaces";

function startTimer(milliseconds: number, timerId: MutableRefObject<number>, callback: () => void) {
  timerId.current = setTimeout(() => {
    callback();
  }, milliseconds) as unknown as number;
}

function clearTimer(timerId: MutableRefObject<number>) {
  if (timerId.current > -1) {
    clearTimeout(timerId.current);
  }
}

export function useTimer(milliseconds: number): IUseTimerResult {
  const [started, setStarted] = useState(false);
  const [completed, setCompleted] = useState(false);
  const timerId = useRef(-1);

  const start = useCallback(() => {
    if (!started) {
      setStarted(true);
      startTimer(milliseconds, timerId, () => setCompleted(true));
    }
  }, [milliseconds, timerId, started]);

  const reset = useCallback(() => {
    clearTimer(timerId);
    setStarted(false);
    setCompleted(false);
  }, [timerId]);

  useEffect(() => {
    return () => clearTimer(timerId);
  }, []);

  return {
    started,
    completed,
    running: started && !completed,
    start,
    reset
  };
}
