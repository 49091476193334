import * as locales from "./locales";

export function getLocale(userLocale: string | undefined): Locale {
  if (!userLocale) {
    return locales.enGB;
  }

  const localeShort = userLocale.toLowerCase().split("-")[0];
  if (localeShort === "en") {
    return locales.enGB;
  }

  const supportedLocales = Object.entries(locales);
  const foundLocale = supportedLocales.find(([key, _value]) => key === localeShort);
  return foundLocale?.[1] ?? locales.enGB;
}
