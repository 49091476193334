import { useState } from "react";
import { useOnce } from "../common/use-once";
import { defaultUserContext } from "./defaultUserContext";
import { IUserPermission } from "../../services/security";
import { IUserPermissionQueryResult, IUserProps, useUserPermissionQuery } from ".";

export interface IUseUserPermissionQueryResultResult {
  userPermissionResult: IUserPermissionQueryResult;
}

export function useUserPermissionQueryResult({ userId }: IUserProps): IUseUserPermissionQueryResultResult {
  const [userPermissionResult, setUserPermissionResult] = useState(defaultUserContext.userPermissionResult);
  const { query } = useUserPermissionQuery({ userId });

  useOnce(() => {
    const { status, isError, isLoading, isSuccess, error, data } = query;

    setUserPermissionResult({
      status,
      isError,
      isLoading,
      isSuccess,
      error,
      data: data as IUserPermission[]
    });
  }, query.isSuccess);

  useOnce(() => {
    const { status, isError, isLoading, isSuccess, error } = query;

    setUserPermissionResult({
      status,
      isError,
      isLoading,
      isSuccess,
      error,
      data: userPermissionResult.data // default
    });
  }, query.isError);

  return {
    userPermissionResult
  };
}
