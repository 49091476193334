import { createIcon } from "@chakra-ui/react";

export const LogOutIcon = createIcon({
  displayName: "LogOutIcon",
  viewBox: "0 0 18.33 20",
  path: (
    <path
      d="M8.61342 0.399902C8.61342 0.179036 8.86138 0 9.1669 0C9.47243 0 9.72038 0.179036 9.72038 0.399902V7.62272C9.72038 7.84359 9.47259 8.02262 9.1669 8.02262C8.86121 8.02262 8.61342 7.84359 8.61342 7.62272V0.399902ZM11.938 3.20003C11.6513 3.09456 11.5042 2.77604 11.6095 2.48828C11.7144 2.20068 12.0318 2.05306 12.3185 2.15853C14.0962 2.81201 15.6168 4.00407 16.6847 5.53841C17.7241 7.03158 18.334 8.8457 18.334 10.7996C18.334 13.3397 17.3076 15.6398 15.6486 17.305C13.9896 18.9699 11.6979 20.0002 9.1669 20.0002C6.63594 20.0002 4.34417 18.9701 2.68519 17.305C1.02636 15.6398 0 13.3397 0 10.7996C0 8.8457 0.609754 7.03158 1.64909 5.53841C2.71697 4.00407 4.23763 2.81201 6.01532 2.15853C6.30187 2.05322 6.6194 2.20085 6.72432 2.48828C6.8294 2.77588 6.68232 3.09456 6.39577 3.20003C4.82938 3.77588 3.49084 4.82406 2.5527 6.17188C1.64147 7.48112 1.1068 9.0765 1.1068 10.7996C1.1068 13.0332 2.00911 15.0555 3.46781 16.5195C4.92652 17.9836 6.94146 18.8893 9.1669 18.8893C11.3923 18.8893 13.4073 17.9837 14.866 16.5195C16.3247 15.0557 17.227 13.0332 17.227 10.7996C17.227 9.0765 16.6923 7.48112 15.7811 6.17188C14.8428 4.82389 13.5044 3.77572 11.938 3.20003Z"
    />
  ),
});
