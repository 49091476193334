import { createIcon } from "@chakra-ui/react";

export const LowPriority = createIcon({
  displayName: "LowPriority",
  viewBox: "0 0 18 22",
  path: (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="16" width="12" height="4" fill="#4B7CF2" />
      <rect x="0.5" y="0.5" width="17" height="21" stroke="#4B7CF2" />
    </svg>
  ),
});
