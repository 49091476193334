import { extendTheme, Theme } from "@chakra-ui/react";
import { colors } from "./colors";
import { typography } from "./typography";
import { Button } from "./buttons";
import { Checkbox } from "./checkbox";
import { Radio } from "./radioButton";
import {
  ProfileBadgeGreetingCmp,
  ProfileBadgeAvatarCmp,
  ProfileBadgeMenuAvatarCmp,
  ProfileBadgeMenuIconCmp,
  Menu,
  MenuBadgeCmp
} from "../profileBadgeStyle";

const theme: Partial<Theme> = extendTheme({
  components: {
    ProfileBadgeGreetingCmp,
    ProfileBadgeAvatarCmp,
    ProfileBadgeMenuAvatarCmp,
    ProfileBadgeMenuIconCmp,
    Menu,
    MenuBadgeCmp,
    Button,
    Checkbox,
    Radio,
  },
  colors,
  ...typography,
  shadows: {
    outline: `0 0 0 3px ${colors.highlight[300]}`,
    checkbox: `0 0 0 3px ${colors.highlight[200]}`
  }
});

export { theme };
