import { useState } from "react";
import { useOnce } from "../common/use-once";
import { defaultUserContext } from "./defaultUserContext";
import { IUser } from "../../services/security";
import { IUserProps, IUserQueryResult, useUserQuery } from ".";
import { useTranslation } from "react-i18next";

export interface IUseUserQueryResultResult {
  userResult: IUserQueryResult;
}

export function useUserQueryResult({ userId }: IUserProps): IUseUserQueryResultResult {
  const { i18n } = useTranslation();
  const [userResult, setUserResult] = useState(defaultUserContext.userResult);
  const { query } = useUserQuery({ userId });

  useOnce(async () => {
    const data = query.data;
    const { status, isError, isLoading, isSuccess, error } = query;

    await i18n.changeLanguage(data?.user_profile.user_lang);

    setUserResult({
      status,
      isError,
      isLoading,
      isSuccess,
      error,
      data: data as Partial<IUser>
    });
  }, query.isSuccess);

  useOnce(() => {
    const { status, isError, isLoading, isSuccess, error } = query;

    setUserResult({
      status,
      isError,
      isLoading,
      isSuccess,
      error,
      data: userResult.data // default
    });
  }, query.isError);

  return {
    userResult
  };
}
