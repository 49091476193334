import { Grid } from "@chakra-ui/layout";
import { ContentCard } from "components/common/content-card";
import { DEFAULT_SPACING } from "constants/spacing";
import { LogoFull } from "styles/icons";
import { H1 } from "components/common/heading";
import { IDefaultLayoutProps } from "./interfaces";

export function DefaultLayout({ children, heading }: IDefaultLayoutProps) {
  return (
    <Grid minWidth="100vw" minHeight="100vh" justifyContent="center" alignContent="center" overflowY="auto">
      <ContentCard
        display="grid"
        gridTemplateColumns="1fr"
        gap={DEFAULT_SPACING}
        width="600px"
        maxWidth="100vw"
      >
        <LogoFull w="auto" height="45px" />
        <H1 data-testid="heading">{heading}</H1>
        { children }
      </ContentCard>
    </Grid>
  );
}
