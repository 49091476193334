import { useMemo } from "react";
import { useUser } from "lib/user";
import { PermissionContext } from "constants/permissionContext";
import { IUserPermission } from "services/security";
import { IGetPermissionContextProps, IUsePermissionContextProps, IUsePermissionContextResult } from ".";

/**
 * Attempts to retrieve a valid permission context for the given permission
 * and context information.
 * @returns The first matching permission context, otherwise undefined.
 */
export function getPermissionContext({
  userPermissions,
  permission,
  appId,
  studySiteId,
  subgroupId,
  studyId
}: IGetPermissionContextProps): IUserPermission | undefined {
  const { STUDY_SITE, SUBGROUP, STUDY, APPLICATION } = PermissionContext;

  if (!userPermissions || !permission) {
    return undefined;
  }

  // find the first matching permission context
  return userPermissions
    .filter((item: IUserPermission) => item.permissions.includes(permission))
    .find((item: IUserPermission) =>
      (!!studySiteId && item.context_type === STUDY_SITE && item.context_uuid === studySiteId) ||
      (!!subgroupId && item.context_type === SUBGROUP && item.context_uuid === subgroupId) ||
      (!!studyId && item.context_type === STUDY && item.context_uuid === studyId) ||
      (!!appId && item.context_type === APPLICATION && item.context_uuid === appId)
    );
}

/**
 * Attempts to retrieve a valid permission context for the given permission
 * and context information.
 * @returns The first matching permission context, otherwise undefined.
 */
export function usePermissionContext({
  permission,
  appId,
  studySiteId,
  subgroupId,
  studyId
}: IUsePermissionContextProps): IUsePermissionContextResult {
  const { userPermissionResult } = useUser();

  // find the first matching permission context
  const permissionWithContext = useMemo(() => getPermissionContext({
    userPermissions: userPermissionResult?.data,
    permission,
    appId,
    studySiteId,
    subgroupId,
    studyId
  }), [userPermissionResult?.data, permission, appId, studySiteId, subgroupId, studyId]);

  return {
    permissionWithContext
  };
}
