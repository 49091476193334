const Checkbox = {
  baseStyle: {
    label: {
      color: "body.900",
      _checked: { color: "highlight.600", _disabled: { color: "body.600" }, },
      _disabled: { color: "body.600", opacity: 1, _hover: { color: "body.600", opacity: 1 } },
      _hover: { color: "highlight.600" },
    },
    control: {
      transitionProperty: "box-shadow",
      transitionDuration: "normal",
      border: "1px solid",
      borderRadius: "2px",
      borderColor: "body.900",
      color: "mainLayout.100",
      _hover: {
        bg: "highlight.200",
        borderColor: "highlight.600",
      },
      _checked: {
        bg: "highlight.600",
        borderColor: "highlight.600",
        color: "mainLayout.100",
        _hover: {
          bg: "highlight.600",
          borderColor: "transparent",
        },
        _disabled: {
          borderColor: "body.600",
          bg: "body.500",
          color: "body.200",
        },
      },
      _disabled: {
        bg: "body.500",
        borderColor: "body.600",
        _hover: {
          bg: "body.500",
          borderColor: "body.600",
        }
      },
      _focus: {
        boxShadow: "checkbox",
      },
      _invalid: {
        borderColor: "notifications.100",
      },
    }
  }
};

export { Checkbox };
