import auth from "./config/auth.json";
import apis from "./config/apis.json";
import { FetchClient } from "lib/auth/fetchClient";

export const msalAuthorities = {
  ad_b2c_signin_policy: `${auth.b2cTenant}/${auth.adb2cSignInPolicy}`,
  ad_b2c_form_completion_policy: `${auth.b2cTenant}/${auth.adb2cFormCompletionPolicy}`,
};

const networkClient = new FetchClient();

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: `${auth.clientId}`,
    authority: msalAuthorities.ad_b2c_signin_policy,
    knownAuthorities: auth.knownAuthorities,
    redirectUri: auth.redirectUri,
    postLogoutRedirectUri: auth.postLogoutRedirectUri,
    navigateToLoginRequestUrl: auth.navigateToLoginRequestUrl,
  },
  system: {
    networkClient,
  }
};

// Scopes you add here will be prompted for consent during login
// NOTE: It's only possible to retrieve scopes for a single resource at
// a time, as each resource requires its own access token.
export const loginRequest = { scopes: [apis.security.scope] };

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};
