import { createIcon } from "@chakra-ui/react";

export const Calendar = createIcon({
  displayName: "Calendar",
  viewBox: "0 0 19 20",
  path: (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.582397" y="1.83333" width="17.6667" height="17.6667" rx="1.5" stroke="currentColor" />
      <path d="M0.0823975 3.93334C0.0823975 2.27648 1.42554 0.933334 3.0824 0.933334H15.7491C17.4059 0.933334 18.7491 2.27648 18.7491 3.93334V4.66667H0.0823975V3.93334Z" fill="currentColor" />
      <rect x="4.0824" width="2.66667" height="1.33333" fill="currentColor" />
      <rect x="12.0824" width="2.66667" height="1.33333" fill="currentColor" />
    </svg>
  ),
});
