import { IKeyValue } from "../interfaces";

export type UriComponent = string | number | boolean;

export abstract class QueryString {
  /**
   * Serialises the input object into a query string.
   *
   * @example
   * const input = {
   *  bar: "one",
   *  foo: "two",
   *  baz: undefined
   * };
   *
   * QueryString.from(input); // returns "?bar=one&foo=two&baz"
   *
   * @param input Object that represents the query parameters.
   * @returns the query string.
   */
  static from(input: Record<string, UriComponent | undefined> | undefined): string {
    if (!input) {
      return "";
    }

    return "?" + Object.getOwnPropertyNames(input)
      .map(key => {
        // no value, return key only
        if (input[key] === undefined) {
          return key;
        }

        return `${key}=${encodeURIComponent(input[key] as UriComponent)}`;
      })
      .join("&");
  }

  /**
   * Parses a query string into an `Array` of `IKeyValue`.
   * @param search The `location.search` value.
   * @returns An `Array` of `IKeyValue`.
   */
  static toKeyValues(search: string): IKeyValue[] {
    return search.split("?")?.[1]?.split("&")?.map(kvp => kvp.split("="))?.map(kvp => ({
      key: kvp[0],
      value: decodeURIComponent(kvp[1])
    })) ?? [];
  }
}
