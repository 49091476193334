
import { createIcon } from "@chakra-ui/react";

const VIEWBOX_X = 3;
const OFFSET = 5;
const WIDTH = 12;
const X = WIDTH + OFFSET;

export const LastPageIcon = createIcon({
  displayName: "LastPageIcon",
  viewBox: `${VIEWBOX_X} 0 ${WIDTH} 20`,
  path: (
    <>
      <path d="M1.45985 20L0 18.5401L8.61313 9.99999L0 1.45985L1.45985 0L11.5328 9.99999L1.45985 20Z" fill="currentColor"/>
      <line x1={X} y1="0" x2={X} y2="20" stroke="currentColor" strokeWidth="2"></line>
    </>
  ),
});
