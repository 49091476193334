import { useCallback } from "react";
import { parseISO, format } from "date-fns";
import { getLocale } from "lib/common";
import { useUser } from "lib/user";

type DateValue = string | undefined | null;

/**
 * Parses the provided ISO `date` string
 * and formats it according to the `userLocale` and `dateFormat` parameters.
 * @param userLocale The user's locale.
 * @param date The ISO date string.
 * @param dateFormat The desired output date format.
 * @returns The formatted date string.
 */
function getLocalisedDate(userLocale: string, date: DateValue, dateFormat: string) {
  const validInputs = date && date !== "" &&
    dateFormat && dateFormat !== "";

  return validInputs ?
    format(parseISO(date as string), dateFormat, {
      locale: getLocale(userLocale)
    }) : "";
}

export interface IUseDateResult {
  getLocalisedDate: (date: DateValue, dateFormat: string) => string;
}

/**
 * @returns The `function` `getLocalisedDate`, which parses the provided ISO date string
 * and formats it according to the `userLocale` and `dateFormat` parameters.
 */
export function useDate(): IUseDateResult {
  const { userResult } = useUser();
  const userLocale = userResult.data?.user_profile?.user_locale;

  const getLocalisedDateCallback = useCallback((date: DateValue, dateFormat: string) => {
    return getLocalisedDate(userLocale ?? "", date, dateFormat);
  }, [userLocale]);

  return {
    getLocalisedDate: getLocalisedDateCallback
  };
}
