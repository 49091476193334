import { createIcon } from "@chakra-ui/react";

export const MenuIconNotification = createIcon({
  displayName: "MenuIconNotification",
  path: (
    <svg width="30" height="30" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.6035 15.268V13.3984C21.6035 10.671 19.7283 8.35934 17.1591 7.6043V6.75781C17.1591 5.78855 16.3435 5 15.3409 5C14.3384 5 13.5228 5.78855 13.5228 6.75781V7.6043C10.9535 8.35934 9.07832 10.671 9.07832 13.3984V15.268C9.07832 17.6638 8.13376 19.9355 6.41865 21.6648C6.25057 21.8342 6.20334 22.0843 6.29861 22.3002C6.39389 22.5161 6.61328 22.6562 6.85611 22.6562H12.3716C12.6531 23.9919 13.8775 25 15.3409 25C16.8044 25 18.0287 23.9919 18.3103 22.6562H23.8257C24.0686 22.6562 24.2879 22.5161 24.3832 22.3002C24.4785 22.0843 24.4312 21.8342 24.2632 21.6648C22.5481 19.9355 21.6035 17.6638 21.6035 15.268ZM14.7349 6.75781C14.7349 6.43473 15.0067 6.17188 15.3409 6.17188C15.6751 6.17188 15.947 6.43473 15.947 6.75781V7.37219C15.7475 7.35359 15.5454 7.34375 15.3409 7.34375C15.1365 7.34375 14.9343 7.35359 14.7349 7.37219V6.75781ZM15.3409 23.8281C14.5508 23.8281 13.8772 23.3382 13.627 22.6562H17.0549C16.8047 23.3382 16.131 23.8281 15.3409 23.8281ZM8.16236 21.4844C9.5426 19.6956 10.2904 17.5309 10.2904 15.268V13.3984C10.2904 10.7061 12.5561 8.51562 15.3409 8.51562C18.1258 8.51562 20.3914 10.7061 20.3914 13.3984V15.268C20.3914 17.5309 21.1393 19.6956 22.5195 21.4844H8.16236Z" fill="currentColor" />
      <path d="M23.2197 13.3984C23.2197 13.722 23.491 13.9844 23.8257 13.9844C24.1604 13.9844 24.4318 13.722 24.4318 13.3984C24.4318 11.0508 23.4862 8.84363 21.7691 7.18359C21.5325 6.9548 21.1487 6.95476 20.912 7.18359C20.6754 7.41242 20.6754 7.7834 20.912 8.01222C22.4002 9.45094 23.2197 11.3638 23.2197 13.3984Z" fill="currentColor" />
      <path d="M6.85606 13.9843C7.19076 13.9843 7.46212 13.722 7.46212 13.3984C7.46212 11.3638 8.28167 9.45095 9.76975 8.01224C10.0064 7.78341 10.0064 7.41243 9.76975 7.18361C9.5331 6.95478 9.14934 6.95478 8.91266 7.18361C7.19561 8.84365 6.25 11.0508 6.25 13.3984C6.25 13.722 6.52135 13.9843 6.85606 13.9843Z" fill="currentColor" />
    </svg>
  ),
});
