import { useContext } from "react";
import { IUserResult } from "./interfaces";
import { UserContext } from "./UserProvider";

/** Provides access to the user details in function components via
 * the `UserProvider`.
 */
export function useUser(): IUserResult {
  const result = useContext(UserContext);
  return result;
}
