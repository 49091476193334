import { createIcon } from "@chakra-ui/react";

export const CloseIcon = createIcon({
  displayName: "CloseIcon",
  path: (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="1.13326" y1="15.1543" x2="15.2754" y2="1.01214" stroke="currentColor" />
      <line x1="15.2758" y1="15.0295" x2="1.13371" y2="0.887367" stroke="currentColor" />
    </svg>
  ),
});
