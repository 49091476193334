import { createIcon } from "@chakra-ui/react";

export const MenuIconSites = createIcon({
  displayName: "MenuIconSites",
  path: (
    <svg width="30" height="30" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.1875 23.125V5.625H7.8125V23.125H5.625V24.375H24.375V23.125H22.1875ZM20.9375 23.125H15.625V20.625H14.375V23.125H9.0625V6.875H20.9375V23.125Z" fill="currentColor" />
      <path d="M12.5 16.875H11.25V18.125H12.5V16.875Z" fill="currentColor" />
      <path d="M15.625 16.875H14.375V18.125H15.625V16.875Z" fill="currentColor" />
      <path d="M18.75 16.875H17.5V18.125H18.75V16.875Z" fill="currentColor" />
      <path d="M12.5 13.125H11.25V14.375H12.5V13.125Z" fill="currentColor" />
      <path d="M15.625 13.125H14.375V14.375H15.625V13.125Z" fill="currentColor" />
      <path d="M18.75 13.125H17.5V14.375H18.75V13.125Z" fill="currentColor" />
      <path d="M12.5 9.375H11.25V10.625H12.5V9.375Z" fill="currentColor" />
      <path d="M15.625 9.375H14.375V10.625H15.625V9.375Z" fill="currentColor" />
      <path d="M18.75 9.375H17.5V10.625H18.75V9.375Z" fill="currentColor" />
    </svg>
  ),
});
