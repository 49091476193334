import { ChakraProvider } from "@chakra-ui/provider";
import { theme } from "styles";
import { Fonts } from "components/fonts";
import SignInLoading from "./SignInLoading";

export default function Preload() {
  return (
    <ChakraProvider theme={theme}>
      <Fonts/>
      <SignInLoading/>
    </ChakraProvider>
  );
}
