import { useMsal } from "@azure/msal-react";
import { getQueryState, QueryState } from "lib/common";
import { useQuery, UseQueryResult } from "react-query";
import { IUserProps } from ".";
import { getUser, IUser } from "../../services/security";

export interface IUseUserQueryResult {
  query: UseQueryResult<IUser | undefined, unknown>;
  user: IUser | undefined;
  state: QueryState;
}

export function useUserQuery({ userId }: IUserProps): IUseUserQueryResult {
  const { instance } = useMsal();

  const query = useQuery(
    ["user", userId],
    async () => await getUser(instance, { userId }),
    { enabled: !!userId }
  );

  const user = query.data;

  return {
    state: getQueryState(query.status),
    query,
    user
  };
}

