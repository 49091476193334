import React from "react";
import { Heading, HeadingProps } from "@chakra-ui/react";

export const H1: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return <Heading as="h1" fontSize="30px" {...rest}>{ children }</Heading>;
};

export const H2: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return <Heading as="h2" fontSize="22px" {...rest}>{ children }</Heading>;
};

export const H3: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return <Heading as="h3" fontSize="20px" {...rest}>{ children }</Heading>;
};

export const Title: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return <Heading as="h4" fontSize="18px" {...rest}>{ children }</Heading>;
};
