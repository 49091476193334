import { useEffect, useRef } from "react";

/** Executes the provided `callback` function once, when the provided `ready` parameter is `true`.
 *
 * @param callback
 * @param ready
 */
export function useOnce(callback: () => void, ready: boolean): void {
  const isLoaded = useRef(false);

  useEffect(() => {
    if (!isLoaded.current && ready) {
      isLoaded.current = true;
      callback();
    }
  }, [ready, callback]);
}
