/**
 * Replaces all instances of a `search` string in an `input` string with a `replacement` string.
 * @param input The string to process.
 * @param search The string to find and replace in the `input` string.
 * @param replacement The string to insert as a replacement for the `search` string.
 */
export function replaceAll(input = "", search = "", replacement = "") {
  if (search === "") {
    return input;
  }

  return input.split(search).join(replacement);
}
