import { EffectCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

/**
 * When the history location changes, fire an EffectCallback function.
 * @param onChange The EffectCallback function.
 */
export function useHistoryChange(onChange: EffectCallback): void {
  const history = useHistory();
  const historyUrl = `${history.location.pathname}${history.location.search}${history.location.hash}`;
  const previousHistoryUrl = useRef<string>(historyUrl);

  useEffect(() => {
    if (historyUrl !== previousHistoryUrl.current) {
      previousHistoryUrl.current = historyUrl;
      onChange();
    }
  }, [historyUrl, previousHistoryUrl, onChange]);
}
