import React from "react";
import { useTranslation } from "react-i18next";
import { SignInLayout, LoadingProgress, SignInContent, SignInLink, SubTitleContent } from "layouts/sign-in";

interface ILoadingPageProps {
  isError?: boolean;
  name?: string;
}

const SignInLoadingErrorContent: React.FC = () => {
  const { t } = useTranslation();
  const signInError = t("signIn.error", "You don't have assigned any application under your account.");

  return (
    <SubTitleContent>{signInError}</SubTitleContent>
  );
};

const SignInLoadingError: React.FC = () => {
  const { t } = useTranslation();
  const signInError = t("signIn.pleaseContactYourAdministrator", "Please contact your administrator.");
  const errorTitle = t("signIn.errorTitle", "Oops!");

  // TODO: add administrator email address to config, to allow replacement in pipeline

  return (
    <SignInLayout>
      <SignInContent
        title={errorTitle}
        subTitle={<SignInLoadingErrorContent/>}
        titleTestId="lpErrorTitle"
        subTitleTestId="lpErrorMsg"
      />
      <SignInLink href="mailto:val-admin@ixico.com" data-testid="lpContactLink">{signInError}</SignInLink>
    </SignInLayout>
  );
};

const SignInLoadingProgressContent: React.FC = () => {
  const { t } = useTranslation();
  const weAreLoadingYourAccount = t("signIn.weAreLoadingYourAccount", "We are loading your account.");
  const pleaseWait = t("signIn.pleaseWait", "Please wait...");

  return (
    <SubTitleContent>
      {weAreLoadingYourAccount}<br/>
      {pleaseWait}
    </SubTitleContent>
  );
};

const SignInLoadingProgress: React.FC<{ name: string }> = ({ name }) => {
  const { t } = useTranslation();
  const welcomeBack = t("signIn.welcomeBack", "Welcome back");

  return (
    <SignInLayout>
      <SignInContent
        title={`${welcomeBack} ${name}`}
        subTitle={<SignInLoadingProgressContent/>}
        titleTestId="lpWelcomeTitle"
        subTitleTestId="lpWelcomeMsg"
      />
      <LoadingProgress/>
    </SignInLayout>
  );
};

const SignInLoading: React.FC<ILoadingPageProps> = ({ isError, name }) => {
  if (isError) {
    return (
      <SignInLoadingError></SignInLoadingError>
    );
  }

  return (
    <SignInLoadingProgress name={name ?? ""}></SignInLoadingProgress>
  );
};

export default SignInLoading;
