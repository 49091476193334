import { createIcon } from "@chakra-ui/react";

export const ReplyIcon = createIcon({
  displayName: "ReplyIcon",
  path: (
    <svg viewBox="0 0 15 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 11L9 17L7.58 15.58L11.17 12H0V0H2V10H11.17L7.58 6.42L9 5L15 11Z" fill="currentColor"/>
      <path d="M15 11L9 17L7.58 15.58L11.17 12H0V0H2V10H11.17L7.58 6.42L9 5L15 11Z" fill="currentColor"/>
    </svg>
  ),
});
