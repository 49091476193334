import { createIcon } from "@chakra-ui/react";

export const HistoryIcon = createIcon({
  displayName: "HistoryIcon",
  viewBox: "0 0 18 18",
  path: (
    <>
      <path d="M8.99999 0C6.63654 0.00126034 4.36823 0.931194 2.68404 2.58933V0H1.48279V4.805H6.28779V3.60375H3.37052C4.09868 2.84478 4.97267 2.24075 5.94003 1.8279C6.9074 1.41506 7.94821 1.20191 8.99999 1.20125C13.3001 1.20125 16.7987 4.69989 16.7987 8.99999C16.7987 13.3001 13.3001 16.7987 8.99999 16.7987C4.69989 16.7987 1.20125 13.3001 1.20125 8.99999H0C-2.21563e-06 10.78 0.527837 12.5201 1.51677 14.0001C2.5057 15.4802 3.91131 16.6337 5.55584 17.3149C7.20038 17.9961 9.00998 18.1743 10.7558 17.8271C12.5016 17.4798 14.1053 16.6226 15.364 15.364C16.6226 14.1053 17.4798 12.5016 17.8271 10.7558C18.1743 9.00998 17.9961 7.20038 17.3149 5.55584C16.6337 3.91131 15.4802 2.5057 14.0001 1.51677C12.5201 0.527837 10.78 -2.21563e-06 8.99999 0Z" fill="currentColor"/>
      <path d="M8.38996 3.60192L8.36987 10.2106H13.195V9.00939H9.5748L9.59121 3.6056L8.38996 3.60192Z" fill="currentColor"/>
    </>
  ),
});
