import { createIcon } from "@chakra-ui/react";

export const MenuIconDashboard = createIcon({
  displayName: "MenuIconDashboard",
  path: (
    <svg width="30" height="30" viewBox="0 0 31 31" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_95:1496)">
        <path d="M12.7083 11.6667H6.45828C5.65414 11.6667 5 11.0126 5 10.2083V6.45828C5 5.65414 5.65414 5 6.45828 5H12.7083C13.5126 5 14.1667 5.65414 14.1667 6.45828V10.2083C14.1667 11.0126 13.5126 11.6667 12.7083 11.6667ZM6.45828 6.25C6.34338 6.25 6.25 6.34338 6.25 6.45828V10.2083C6.25 10.3233 6.34338 10.4167 6.45828 10.4167H12.7083C12.8233 10.4167 12.9167 10.3233 12.9167 10.2083V6.45828C12.9167 6.34338 12.8233 6.25 12.7083 6.25H6.45828Z" fill="currentColor" />
        <path d="M12.7083 25H6.45828C5.65414 25 5 24.3459 5 23.5417V14.7917C5 13.9874 5.65414 13.3333 6.45828 13.3333H12.7083C13.5126 13.3333 14.1667 13.9874 14.1667 14.7917V23.5417C14.1667 24.3459 13.5126 25 12.7083 25ZM6.45828 14.5833C6.34338 14.5833 6.25 14.6767 6.25 14.7917V23.5417C6.25 23.6566 6.34338 23.75 6.45828 23.75H12.7083C12.8233 23.75 12.9167 23.6566 12.9167 23.5417V14.7917C12.9167 14.6767 12.8233 14.5833 12.7083 14.5833H6.45828Z" fill="currentColor" />
        <path d="M23.5417 25H17.2917C16.4874 25 15.8333 24.3459 15.8333 23.5417V19.7917C15.8333 18.9874 16.4874 18.3333 17.2917 18.3333H23.5417C24.3459 18.3333 25 18.9874 25 19.7917V23.5417C25 24.3459 24.3459 25 23.5417 25ZM17.2917 19.5833C17.1767 19.5833 17.0833 19.6767 17.0833 19.7917V23.5417C17.0833 23.6566 17.1767 23.75 17.2917 23.75H23.5417C23.6566 23.75 23.75 23.6566 23.75 23.5417V19.7917C23.75 19.6767 23.6566 19.5833 23.5417 19.5833H17.2917Z" fill="currentColor" />
        <path d="M23.5417 16.6667H17.2917C16.4874 16.6667 15.8333 16.0126 15.8333 15.2083V6.45828C15.8333 5.65414 16.4874 5 17.2917 5H23.5417C24.3459 5 25 5.65414 25 6.45828V15.2083C25 16.0126 24.3459 16.6667 23.5417 16.6667ZM17.2917 6.25C17.1767 6.25 17.0833 6.34338 17.0833 6.45828V15.2083C17.0833 15.3233 17.1767 15.4167 17.2917 15.4167H23.5417C23.6566 15.4167 23.75 15.3233 23.75 15.2083V6.45828C23.75 6.34338 23.6566 6.25 23.5417 6.25H17.2917Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_95:1496">
          <rect width="20" height="20" fill="white" transform="translate(5 5)" />
        </clipPath>
      </defs>
    </svg>
  ),
});
