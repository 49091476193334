import { createIcon } from "@chakra-ui/react";

export const ChevronRight = createIcon({
  displayName: "ChevronRight",
  viewBox: "0 0 12 20",
  path: (
    <path d="M1.45985 20L0 18.5401L8.61313 9.99999L0 1.45985L1.45985 0L11.5328 9.99999L1.45985 20Z" fill="currentColor"/>
  ),
});

