import { EventType, EventMessage, AuthError, BrowserUtils } from "@azure/msal-browser";
import { IdTokenClaims } from "lib/auth";

export function isInvalidGrant(event: EventMessage) {
  return (event.error as AuthError | undefined)?.errorCode === "invalid_grant";
}

export function isLoginSuccess(event: EventMessage) {
  return event.eventType === EventType.LOGIN_SUCCESS && !!event.payload;
}

export function isForgotPassword(idTokenClaims: IdTokenClaims | undefined) {
  return idTokenClaims?.isForgotPassword === true;
}

export function isAcquireTokenFailure(event: EventMessage) {
  // Events with eventType `EventType.ACQUIRE_TOKEN_FAILURE` raised within an MSAL popup window are ignored,
  // see https://dev.azure.com/IXIDEVOPS/OASIS/_workitems/edit/25196#14477408 for details.
  const isAuthPopup = BrowserUtils.isInPopup();
  return event.eventType === EventType.ACQUIRE_TOKEN_FAILURE && !isAuthPopup;
}
