import { IPublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { IIdTokenClaims } from "./interfaces";
import auth from "../../config/auth.json";
import { msalAuthorities } from "authConfig";

const getSilentRequest = (instance: IPublicClientApplication, scope: string): SilentRequest => {
  const accounts = instance.getAllAccounts();
  const regex = new RegExp(auth.adb2cSignInPolicy, "i");
  const account = accounts.find(acc => acc.homeAccountId.match(regex));

  return {
    scopes: [scope],
    account,
    authority: msalAuthorities.ad_b2c_signin_policy,
  };
};

/**
 * Returns an access token for the given resource scope.
 *
 * @param instance msal instance of IPublicClientApplication
 * @param scope Security service AZ ADB2C scope
 */
export async function getAccessToken(instance: IPublicClientApplication, scope: string): Promise<string> {
  const { accessToken } = await instance.acquireTokenSilent(getSilentRequest(instance, scope));

  if (!accessToken) {
    throw new Error(`Failed to retrieve access token for scope ${scope}`);
  }

  return accessToken;
}

/**
 * Returns the current user's id token claims.
 *
 * @param instance msal instance of IPublicClientApplication
 * @param scope Security service AZ ADB2C scope
 */
export async function getAccessTokenClaims(instance: IPublicClientApplication, scope: string): Promise<IIdTokenClaims> {
  const result = await instance.acquireTokenSilent(getSilentRequest(instance, scope));
  return result.account?.idTokenClaims as IIdTokenClaims;
}
