import { ErrorLayout } from "layouts/error";
import { useCallback } from "react";
import { IErrorComponentProps } from "./interfaces";

/**
 * Generic error component that displays an error message and a button to return to the home page.
 */
export function ErrorComponent(props: IErrorComponentProps) {
  const errorCode = props.errorCode ?? "unknown error";

  const onButtonClick = useCallback(() => {
    // Return the user to the home page.
    window.location.href = "/";
  }, []);

  return (
    <ErrorLayout
      title="Error"
      heading="An error occurred"
      message={errorCode}
      messageTestId="error"
      buttonText="Back to home page"
      buttonTestId="back-to-home-page"
      onButtonClick={onButtonClick}/>
  );
}
