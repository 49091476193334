import i18next, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

export const defaultLang = "en-GB";

const i18nOptions: InitOptions = {
  debug: false,
  backend: {
    loadPath: "/i18n/{{lng}}.json",
    crossDomain: false
  },
  lng: defaultLang,
  fallbackLng: defaultLang,
  load: "currentOnly",
  react: {
    useSuspense: false,
  }
};

i18next
  // lazy load translation .json files
  .use(HttpApi)
  // passes i18n down to react-i18next
  .use(initReactI18next)
  .init(i18nOptions);

export { i18next };
