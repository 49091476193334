import { IUserResult } from ".";
import { defaultLang } from "../i18n";
import { defaultQueryResult } from "./defaultQueryResult";

export const defaultUserContext: IUserResult = {
  userResult: {
    ...defaultQueryResult,
    data: {
      user_profile: {
        user_lang: defaultLang,
        user_locale: defaultLang
      }
    }
  },
  userPermissionResult: {
    ...defaultQueryResult,
    data: []
  },
  userTokenClaimsResult: {
    ...defaultQueryResult,
    data: {
      family_name: "",
      given_name: "",
      extension_oasis_user_id: ""
    }
  }
};
