import { QueryState } from "lib/common";
import { QueryStatus } from "react-query";

export function getQueryState(
  status: QueryStatus
): QueryState {
  switch (status) {
    case "idle": return QueryState.idle;
    case "error": return QueryState.error;
    case "success": return QueryState.success;
    case "loading": return QueryState.loading;
  }
}
