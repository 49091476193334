import { createIcon } from "@chakra-ui/react";

export const HighPriority = createIcon({
  displayName: "HighPriority",
  viewBox: "0 0 18 22",
  path: (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="17" height="21" stroke="#F24B4B"/>
      <rect x="3" y="2.25" width="12" height="17.6" fill="#F24B4B"/>
    </svg>
  ),
});
