import { PublicClientApplication, EventMessage, AuthError } from "@azure/msal-browser";

export function onAcquireTokenFailure(event: EventMessage, msalInstance: PublicClientApplication) {
  // if we failed to acquire a token, logout and redirect to the sign-in page,
  // except in the case of form completion MFA errors
  const { errorCode } = event.error as AuthError;
  const { interactionType } = event;
  console.warn({ interactionType, errorCode });
  switch (errorCode) {
    case "user_cancelled": // MFA - User closes the popup
    case "access_denied": // MFA - User clicks the cancel button inside the popup
    case "server_error": // MFA - User inserts the wrong code multiple times
      break;
    default:
      msalInstance.logoutRedirect();
      break;
  }
}
