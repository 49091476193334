import { FieldError } from "react-hook-form";
import { GroupedFieldErrors } from "./interfaces";

/**
 * Check if the `objectToCheck` is a `FieldError`.
 * @param objectToCheck The object to check.
 * @returns `true` if the object is a `FieldError`, otherwise `false`.
 */
function isFieldError(objectToCheck: object) {
  // validate the object by checking for known properties
  return objectToCheck.hasOwnProperty("type") &&
    objectToCheck.hasOwnProperty("types");
}

/**
 * Resolves the FieldError for the specified `inputName`.
 * Either this is available at the root of the `errors` object,
 * or is grouped by one or more object levels if the `inputName` includes one or more `.` characters.
 * @param inputName The input name.
 * @param errors The `react-hook-form` `errors` object.
 * @returns The `FieldError`.
 */
function getFieldError(inputName: string, errors: GroupedFieldErrors): FieldError | null {
  const fieldError = errors?.[inputName];

  // FieldError
  if (fieldError !== undefined && isFieldError(fieldError as FieldError)) {
    return fieldError as FieldError;
  }

  // GroupedFieldError
  if (inputName.includes(".")) {
    const splitName = inputName.split(".");
    let currentObject: GroupedFieldErrors | FieldError = errors;
    // resolve each object level in turn,
    // until we get to the deepest object referred to by the `inputName` grouping
    let currentIndex = 0;
    while (currentObject !== undefined && currentIndex < splitName.length) {
      const currentName = splitName[currentIndex];
      currentObject = (currentObject as GroupedFieldErrors)[currentName];
      currentIndex++;
    }

    if (currentObject !== undefined && isFieldError(currentObject as FieldError)) {
      return currentObject as FieldError;
    }

    return null;
  }

  return null;
}

/**
 * Extracts error messages from the `react-hook-forms` error object
 * @param fieldName The field name.
 * @param errors The `react-hook-form` errors object.
 * @returns The error messages.
 */
export function extractFormErrorMessages(fieldName: string, errors?: GroupedFieldErrors): string[] {
  if (errors === undefined || Object.keys(errors).length === 0) {
    return [];
  }

  const fieldError = getFieldError(fieldName, errors);
  if (fieldError === null) {
    return [];
  }

  const errorTypes = fieldError.types ?? {};
  const errorsMsgs = Object.keys(errorTypes).map(k => errorTypes[k] as string);
  return errorsMsgs;
}
